<template>
  <el-form :rules="rules" ref="form" :model="form" style="width: 100%;">
    <el-row>
      <el-col :span="10">
        <el-form-item prop="name" label="Organisatienaam">
          <el-input type="text" v-model="form.name" />
          <small class="el-form__help-text">Moet uniek zijn</small>
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="13">
        <el-form-item prop="logo" label="Logo">
          <!-- Logo<br /> -->
          <img
            :src="previewImage"
            v-if="previewImage"
            style="max-width: 320px; clear:both; display: block; margin-bottom: 1rem;"
          />
          <div class="el-input__inner">
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              @change="handleFileUpload($event)"
              id="file-input"
            />
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item prop="address" label="Adres">
          <el-input type="text" v-model="form.address" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <el-form-item prop="zipcode" label="Postcode">
          <el-input type="text" v-model="form.zipcode" />
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="19">
        <el-form-item prop="city" label="Plaats">
          <el-input type="text" v-model="form.city" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item>
          <el-button @click.prevent="submitForm" type="primary"
            >Opslaan</el-button
          >
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
// import { mapState } from 'vuex'
export default {
  name: 'OrganisationForm',
  props: {
    existingFormData: {
      type: Object
    }
  },
  mounted() {
    if (this.existingFormData) {
      this.setExistingData()
    }
  },
  data() {
    return {
      previewImage: '',
      form: {
        name: '',
        address: '',
        zipcode: '',
        city: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: 'De naam van de organisatie mag niet leeg zijn',
            trigger: ['blur', 'change']
          }
        ],
        address: [
          {
            required: true,
            message: 'Het adres van de organisatie mag niet leeg zijn',
            trigger: ['blur', 'change']
          }
        ],
        zipcode: [
          {
            required: true,
            message: 'De postcode van de organisatie mag niet leeg zijn',
            trigger: ['blur', 'change']
          }
        ],
        city: [
          {
            required: true,
            message: 'De plaats van de organisatie mag niet leeg zijn',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    setExistingData() {
      this.form.name = this.existingFormData.name
      this.form.address = this.existingFormData.address
      this.form.zipcode = this.existingFormData.zipcode
      this.form.city = this.existingFormData.city
      this.form.city = this.existingFormData.city

      if (this.existingFormData.logo) {
        this.previewImage = this.existingFormData.logo.url
      }
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submitted', this.form)
          // this.$refs.form.resetFields()
        }
      })
    },
    handleFileUpload(e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e => {
        this.previewImage = e.target.result
      }
      this.form.logo = image
    }
  }
}
</script>
