import { Api } from '@/config'
import Manual from '../models/Manual'
const state = {
  activeTemplate: null,
  templates: []
}

const actions = {
  all({ commit }) {
    return new Promise((resolve, reject) => {
      commit('resetTemplateList')
      Api.get('templates/all').then(
        response => {
          if (response?.status === 200) {
            response.data.forEach(m => {
              commit('addTemplateToList', new Manual(m))
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  copyTemplateToTemplate({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      Api.post(`templates/${id}/copy`).then(
        response => {
          if (response && response.status === 200) {
            commit('addTemplateToList', new Manual(response.data))
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  copyTemplateToManual({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Api.post(
        `organisations/${payload.organisation_id}/templates/${payload.template_id}/copyToManual`,
        {
          title: payload.title,
          project_number: payload.project_number,
          version: payload.version,
          default_version_date: payload.default_version_date
        }
      ).then(
        response => {
          if (response && response.status === 200) {
            commit('manual/addManualToList', new Manual(response.data), {
              root: true
            })
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getTemplate({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      Api.get(`templates/${id}`).then(
        response => {
          if (response?.status === 200) {
            commit('manual/setActiveManual', new Manual(response.data), {
              root: true
            })
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  }
}

const mutations = {
  resetTemplateList(state) {
    state.templates = []
  },
  addTemplateToList(state, template) {
    state.templates.push(template)
  },
  removeTemplate(state, templateId) {
    const templateIndex = state.templates.findIndex(u => u.id === templateId)
    if (templateIndex >= 0) {
      state.templates.splice(templateIndex, 1)
    }
  },
  updateTemplateInList(state, { template, templateId }) {
    const templateToUpdate = state.templates.find(m => m.id === templateId)
    if (templateToUpdate) {
      templateToUpdate.setData(template)
    }
  }
}

const getters = {}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
