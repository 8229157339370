<template>
  <div>
    <template v-if="activeOrganisation && user.canViewOrganisation">
      <img :src="activeOrganisation.logo.url" v-if="activeOrganisation.logo" />
      <h2>Gebruikers van "{{ activeOrganisation.name }}"</h2>
      <el-table stripe :data="activeOrganisation.users" v-loading="loading">
        <el-table-column
          sortable
          prop="first_name"
          label="Naam"
          width="150"
        ></el-table-column>
        <el-table-column
          sortable
          prop="last_name"
          label="Achternaam"
        ></el-table-column>
        <el-table-column
          sortable
          prop="roleName"
          label="Rol(len)"
        ></el-table-column>
        <el-table-column
          width="300"
          sortable
          prop="email"
          label="E-mailadres"
        ></el-table-column>
        <el-table-column
          sortable
          align="center"
          prop="accepted_privacy_statement"
          label="Akk. privacy"
          width="110"
        >
          <template slot-scope="scope">
            <i
              class="fa fa-check"
              v-if="scope.row.accepted_privacy_statement"
            ></i>
            <i class="fa fa-times" v-else></i>
          </template>
        </el-table-column>
        <el-table-column width="60" align="right">
          <template slot-scope="scope">
            <context-menu v-if="scope.row.roleName !== 'Super admin'">
              <li>
                <a
                  href=""
                  class="icon-button icon-button--text"
                  @click.prevent="deleteUser(scope.row.id)"
                >
                  <i class="fal fa-trash-alt"></i>
                  Gebruiker verwijderen
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="icon-button icon-button--text"
                  @click.prevent="editUser(scope.row.id)"
                >
                  <i class="fal fa-pen"></i>
                  Gebruiker wijzigen
                </a>
              </li>
            </context-menu>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" @click.prevent="addDialogVisible = true"
        >Voeg een gebruiker toe</el-button
      >

      <el-dialog title="Gebruiker toevoegen" :visible.sync="addDialogVisible">
        <user-form @submitted="addNewUser" :saving="loading" ref="addForm" />
      </el-dialog>
      <el-dialog
        :title="`${userToEdit.name} wijzigen`"
        v-if="userToEdit"
        :visible.sync="editDialogVisible"
        @closed="closeEditDialog"
      >
        <user-form
          @submitted="updateUser"
          :existing-form-data="userToEdit"
          :saving="loading"
        />
      </el-dialog>
    </template>
    <h1 v-else>Geen organisatie gevonden</h1>
  </div>
</template>
<script>
/********
 * User overview is always based on an organisationId.
 **********/
import { mapState } from 'vuex'
import UserForm from '@/components/User/Form'
import ContextMenu from '@/components/Helpers/ContextMenu'
export default {
  name: 'UserOverview',
  components: {
    UserForm,
    ContextMenu
  },
  computed: {
    ...mapState('organisation', ['organisations']),
    ...mapState('user', ['user']),
    activeOrganisation() {
      if (this.$route.params.id) {
        return this.organisations.find(
          o => o.id === parseInt(this.$route.params.id)
        )
      }

      return []
    }
  },
  data() {
    return {
      loading: false,
      addDialogVisible: false,
      editDialogVisible: false,
      userToEdit: null
    }
  },
  mounted() {},
  methods: {
    addNewUser(formData) {
      this.loading = true
      this.$store
        .dispatch('organisation/addNewUser', {
          organisationId: this.activeOrganisation.id,
          data: formData
        })
        .then(
          () => {
            this.$notify({
              title: 'De gebruiker is opgeslagen',
              type: 'success'
            })
            this.addDialogVisible = false
            this.$refs.addForm.resetForm()
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    editUser(userId) {
      const user = this.activeOrganisation.users.find(u => u.id === userId)
      if (user) {
        this.userToEdit = user
        this.editDialogVisible = true
      }
    },
    updateUser(data) {
      this.loading = true
      this.$store
        .dispatch('organisation/updateUser', {
          organisationId: this.activeOrganisation.id,
          userId: this.userToEdit.id,
          data
        })
        .then(
          () => {
            this.$notify({
              title: 'De gebruiker is opgeslagen',
              type: 'success'
            })
            this.editDialogVisible = false
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    closeEditDialog() {
      this.userToEdit = null
      this.editDialogVisible = false
    },
    deleteUser(userId) {
      this.$swal({
        title: 'Weet je het zeker?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuleren',
        confirmButtonText: 'Verwijder deze gebruiker'
      }).then(result => {
        if (result.value) {
          this.loading = true
          this.$store
            .dispatch('organisation/deleteUser', {
              userId,
              organisationId: this.activeOrganisation.id
            })
            .then(
              () => {
                this.$notify({
                  title: 'De gebruiker is verwijderd',
                  type: 'success'
                })
              },
              error => {
                this.$notify({
                  title: 'Er is iets misgegaan. De melding is:',
                  message: error,
                  type: 'error'
                })
              }
            )
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
