<template>
  <el-form
    label-position="top"
    :rules="rules"
    ref="form"
    :model="form"
    v-loading="saving"
  >
    <el-row>
      <el-col :span="11">
        <el-form-item prop="title" label="Titel">
          <el-input type="text" v-model="form.title" />
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="5">
        <el-form-item prop="version" label="Versie">
          <el-input type="text" v-model="form.version" />
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="5">
        <el-form-item prop="version_date" label="Versiedatum">
          <!-- <el-input type="text" v-model="form.version_date" /> -->
          <el-date-picker
            v-model="form.version_date"
            type="date"
            placeholder="Selecteer datum"
            :picker-options="pickerOptions"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <el-form-item prop="element_code" label="Element code">
          <el-input type="text" v-model="form.element_code" />
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="5">
        <el-form-item prop="element_type" label="Type element">
          <el-select v-model="form.element_type">
            <el-option value="Formulier">Formulier</el-option>
            <el-option value="Inleiding">Inleiding</el-option>
            <el-option value="Norm">Norm</el-option>
            <el-option value="Procedure">Procedure</el-option>
            <el-option value="Plan van Aanpak">Plan van Aanpak</el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="5">
        <el-form-item prop="has_page_break" label="Pagina einde">
          <label for="has_page_break">
            <input type="checkbox" id="has_page_break" v-model="form.has_page_break">
              Pagina in print eindigen na element
            </label>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button type="primary" @click.prevent="submitForm"
          >Opslaan</el-button
        >
        <el-button type="link" @click.prevent="cancelForm">Annuleren</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
// import { mapState } from 'vuex'
export default {
  name: 'BlockForm',
  props: {
    saving: {
      type: Boolean,
      required: true
    },
    existingFormData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: 'Dit moment',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          }
        ]
      },
      form: {
        title: '',
        version: '',
        element_code: '',
        element_type: '',
        version_date: '',
        has_page_break: false
      },
      rules: {
        title: [
          {
            required: true,
            message: 'De titel mag niet leeg zijn',
            trigger: ['blur']
          }
        ],
        element_type: [
          {
            required: true,
            message: 'De versie mag niet leeg zijn',
            trigger: ['blur']
          }
        ],
        version: [
          {
            required: true,
            message: 'De versie mag niet leeg zijn',
            trigger: ['blur']
          }
        ],
        version_date: [
          {
            required: true,
            message: 'De versie datum mag niet leeg zijn',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  mounted() {
    if (this.existingFormData) {
      this.form.title = this.existingFormData.title
        ? this.existingFormData.title
        : ''
      this.form.version = this.existingFormData.version
        ? this.existingFormData.version
        : ''
      this.form.element_type = this.existingFormData.element_type
        ? this.existingFormData.element_type
        : ''
      this.form.element_code = this.existingFormData.element_code
        ? this.existingFormData.element_code
        : ''
      this.form.version_date = this.existingFormData.version_date
        ? this.existingFormData.version_date
        : ''
      this.form.has_page_break = this.existingFormData.has_page_break
    }
  },
  methods: {
    cancelForm() {
      this.$emit('cancelled')
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submitted', this.form)
        }
      })
    }
  }
}
</script>
