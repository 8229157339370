<template>
  <div
    class="block draggable is--nested"
    :class="{ 'is--open': blockIsOpen }"
    v-loading="loading"
  >
    <div v-if="isMutating" class="block__heading">
      <block-form
        :existing-form-data="block"
        @submitted="updateBlock"
        @cancelled="cancelEditting"
        :saving="loading"
      />
    </div>
    <div
      v-else
      class="block__heading draggable__dragger"
      @click.prevent="blockIsOpen = !blockIsOpen"
    >
      <div class="block__toggler">
        <button class="icon-button icon-button--toggle">
          <i class="fal fa-chevron-up"></i>
        </button>
        <h1 class="block__title">{{ block.title }}</h1>
        <span class="block__element-type">{{ block.element_code }}</span>
      </div>
      <div class="block__heading--align-right">
        <div class="block__version">Versie {{ block.version }}</div>
        <context-menu v-if="user.isSuperAdmin">
          <li>
            <a
              href="#"
              class="icon-button icon-button--text"
              @click.prevent.stop="editBlock"
            >
              <i class="fal fa-pen"></i>
              Element wijzigen
            </a>
          </li>
          <li>
            <a
              href="#"
              class="icon-button icon-button--text"
              @click.prevent.stop="deleteBlock"
            >
              <i class="fal fa-trash-alt"></i>
              Element verwijderen
            </a>
          </li>
        </context-menu>
      </div>
    </div>
    <div class="block__body" v-show="blockIsOpen">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Revisies" name="revisions">
          <revision-overview
            :chapterId="block.chapter_id"
            :revisions="block.revisions"
          />
        </el-tab-pane>
        <el-tab-pane
          label="Documenten"
          name="documents"
          v-if="!activeManual.is_template"
        >
          <revision-documents
            :chapter-id="block.chapter_id"
            :manual-id="manualId"
            :block-id="block.id"
            :documents="block.documents"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import blockForm from '@/components/Manual/Editor/Block/Form'
import RevisionDocuments from '@/components/Manual/Editor/Revision/Documents'
import RevisionOverview from '@/components/Manual/Editor/Revision/Overview'
import ContextMenu from '../../../Helpers/ContextMenu.vue'
export default {
  name: 'EditorBlock',
  components: {
    blockForm,
    RevisionOverview,
    RevisionDocuments,
    ContextMenu
  },
  props: {
    block: {
      type: Object,
      required: true
    },
    manualId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      blockIsOpen: false,
      loading: false,
      isMutating: false,
      activeTab: 'revisions'
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('manual', ['activeManual'])
  },
  methods: {
    updateBlock(data) {
      data.order = this.block.order // update this manually since the user cant add it in the form
      this.loading = true
      this.$store
        .dispatch('manual/updateBlock', {
          payload: data,
          chapterId: this.block.chapter_id,
          blockId: this.block.id,
          manualId: this.manualId
        })
        .then(
          () => {
            this.$notify({
              title: 'het element is geupdated',
              type: 'success'
            })

            this.cancelEditting()
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    editBlock() {
      this.isMutating = true
      this.$emit('editting')
    },
    cancelEditting() {
      this.isMutating = false
      this.$emit('edittingClosed')
    },
    deleteBlock() {
      this.$swal({
        title: 'Weet je het zeker?',
        html:
          '<trong>LET OP:</strong>Met deze actie worden alle revisies en documenten verwijderd.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuleren',
        confirmButtonText: 'Ik begrijp het. Verwijder dit element'
      }).then(result => {
        if (result.value) {
          this.loading = true
          this.$store
            .dispatch('manual/deleteBlock', {
              blockId: this.block.id,
              chapterId: this.block.chapter_id,
              manualId: this.manualId
            })
            .then(
              () => {
                this.$notify({
                  title: 'Het element is verwijderd',
                  type: 'success'
                })
              },
              error => {
                this.$notify({
                  title: 'Er is iets misgegaan. De melding is:',
                  message: error,
                  type: 'error'
                })
              }
            )
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
