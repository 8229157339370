<template>
  <div class="context-menu" @focusout="clickOutside">
    <button
      class="context-menu__button"
      @click.prevent.stop="contextMenuOpen = !contextMenuOpen"
    >
      <i class="fal fa-ellipsis-v"></i>
    </button>
    <ul class="context-menu__nav" v-if="contextMenuOpen">
      <slot></slot>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ContextMenu',
  data() {
    return {
      contextMenuOpen: false
    }
  },
  methods: {
    clickOutside() {
      setTimeout(() => {
        this.contextMenuOpen = false
      }, 550)
      // It appears that this timeout-value should be larger than about 500, because otherwise it seems to give problems with the contextmenu on touchpads
    }
  }
}
</script>
<style lang="scss">
.context-menu {
  position: relative;
  color: $--color-text-primary;

  &__nav {
    position: absolute;
    right: 20px;
    top: 20px;
    min-width: 250px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09),
      0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    z-index: 999;
    list-style: none;
    padding: 0;
    margin: 0;

    .el-button,
    a {
      color: $--color-text-primary;
    }
  }

  li {
    text-align: left;

    a {
      display: block;
      padding: 0.5rem 0.75rem;
      margin: 0.3rem;
      border-radius: 8px;
      font-size: $--font-size-base;

      &:hover {
        background-color: $--color-primary-light-2;
        text-decoration: none;
      }
    }
  }

  &__button {
    color: $--color-text-primary;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 24px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $--color-primary-light-2;
      outline: 0;
    }
  }
}
</style>
