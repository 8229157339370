import { Api } from '@/config'
import Role from '../models/Role'
const state = {
  availableRoles: []
}

const actions = {
  getAvailableRoles({ commit }) {
    return new Promise((resolve, reject) => {
      Api.get('role/all').then(
        response => {
          if (response?.status === 200) {
            response.data.forEach(r => {
              // Skip the SuperAdmin for now. Superadmins cannot be set from the SPA at this moment
              if (r.name !== 'superAdministrator') {
                commit('addAvailableRole', new Role(r))
              }
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  }
}

const mutations = {
  addAvailableRole(state, r) {
    state.availableRoles.push(r)
  },
  resetAvailableRoles(state) {
    state.availableRoles = []
  }
}

const getters = {}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
