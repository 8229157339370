<template>
  <div v-loading="loading">
    <el-alert
      :title="errorMessage"
      type="error"
      v-if="errorMessage"
      :closable="false"
    ></el-alert>

    <template v-if="success">
      <el-alert
        :title="successMessage"
        type="success"
        :closable="false"
      ></el-alert>
      <br />
    </template>
    <template>
      <p>Voer je e-mailadres.</p>
      <p>
        Je zal een e-mail ontvangen met een link om je wachtwoord te kunnen
        resetten
      </p>
      <el-form
        @submit.native.prevent="resetPassword"
        class="forgot-password-form"
      >
        <el-form-item label="E-mailadres">
          <el-input
            type="text"
            placeholder="Jouw e-mailadres"
            v-model="resetEmail"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click.prevent="resetPassword"
            :disabled="this.resetEmail === ''"
            >Reset wachtwoord</el-button
          >
        </el-form-item>
      </el-form>
    </template>
  </div>
</template>
<script>
export default {
  name: 'PasswordRequest',

  data() {
    return {
      loading: false,
      success: false,
      resetEmail: '',
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    resetPassword() {
      this.errorMessage = ''
      this.success = false
      if (this.resetEmail !== '') {
        this.loading = true
        this.$store
          .dispatch('user/sendPasswordResetEmail', {
            email: this.resetEmail
          })
          .then(
            resp => {
              this.loading = false
              this.success = true
              this.successMessage = resp
            },
            error => {
              let errorMessage = 'no error'
              if (error) {
                errorMessage = error
              }

              if (errorMessage === 'messages.passwords.user') {
                // This usually means that this email isn't found but we won't have to tell the user that. so make it a success
                this.success = true
                this.successMessage =
                  'Als dit e-mailadres bestaat wordt hier een mail naartoe gestuurd'
              } else {
                this.errorMessage = `Er ging iets mis bij het resetten van je wachtwoord: ${errorMessage}`
              }

              this.loading = false
            }
          )
      } else {
        this.errorMessage = 'Het email veld mag niet leeg zijn'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.forgot-password-form {
  margin-top: 2rem;
}
</style>
