<template>
  <el-container class="container">
    <el-card class="reset-password container__card" v-loading="loading">
      <h2>Reset je wachtwoord</h2>
      <el-form
        :rules="rules"
        ref="passwordForm"
        :model="passwordForm"
        v-if="!success && !failed"
      >
        <el-row>
          <el-col :xs="24" :md="11">
            <el-form-item label="Nieuw wachtwoord" prop="password">
              <el-input
                name="password"
                type="password"
                v-model="passwordForm.password"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="2" v-html="'&nbsp;'"></el-col>
          <el-col :xs="24" :md="11">
            <el-form-item label="Nieuw wachtwoord check" prop="passwordRepeat">
              <el-input
                name="passwordRepeat"
                type="password"
                v-model="passwordForm.passwordRepeat"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>&nbsp;</el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-button type="primary" @click.prevent="submitPassword"
              >Verander wachtwoord</el-button
            >
            <el-button
              type="text"
              @click.prevent="$router.push({ name: 'home' })"
              >Terug naar home</el-button
            >
          </el-col>
        </el-row>
      </el-form>

      <p v-if="success && !loading">
        Je wachtwoord is veranderd.
        <router-link :to="{ name: 'home' }">Terug naar home</router-link>
      </p>
      <p v-if="failed && !loading">
        Je wachtwoord kon niet worden veranderd.
        <a href="#" @click.prevent="retry">Probeer opnieuw</a> of
        <router-link :to="{ name: 'home' }">ga terug naar home</router-link>
      </p>
    </el-card>
  </el-container>
</template>
<script>
import { passwordStrings } from '@/config'
export default {
  name: 'ResetPassword',
  data() {
    const checkPasswordMatch = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(passwordStrings.empty))
      } else {
        if (this.passwordForm.passwordRepeat !== '') {
          this.$refs.passwordForm.validateField('passwordRepeat')
        }
        callback()
      }
    }
    const checkPasswordMatch1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(passwordStrings.emptyConfirm))
      } else if (value !== this.passwordForm.password) {
        callback(new Error(passwordStrings.passwordsDontMatch))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      success: false,
      failed: false,
      email: null,
      token: null,
      passwordForm: {
        password: '',
        passwordRepeat: ''
      },
      rules: {
        password: [
          {
            validator: checkPasswordMatch,
            trigger: 'change'
          },
          {
            required: true,
            message: 'Het wachtwoord mag niet leeg zijn',
            trigger: ['blur', 'change']
          },
          {
            min: 8,
            max: 50,
            message:
              'Het wachtwoord moet tussen de 8 en 50 karakters lang zijn',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!$#%]).*$/,
            message:
              'Het wachtwoord moet minimaal bestaan uit 1 hoofdletter, 1 kleine letter, 1 nummer en 1 speciaal karakter.',
            trigger: ['blur', 'change']
          }
        ],
        passwordRepeat: [
          {
            validator: checkPasswordMatch1,
            trigger: 'change'
          },
          {
            required: true,
            message: 'De wachtwoord bevestiging mag niet leeg zijn',
            trigger: ['blur', 'change']
          },
          {
            min: 8,
            max: 50,
            message:
              'De wachtwoord bevestiging moet tussen de 8 en 50 karakters lang zijn',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  mounted() {
    if (this.$route.query?.token && this.$route.query?.email) {
      this.token = this.$route.query.token
      this.email = this.$route.query.email
    } else {
      this.failed = true
    }
  },
  methods: {
    retry() {
      this.loading = false
      this.success = false
      this.failed = false
    },
    submitPassword() {
      this.$refs.passwordForm.validate(valid => {
        if (valid && this.token && this.email) {
          this.loading = true
          this.success = false
          this.failed = false
          this.$store
            .dispatch('user/resetPassword', {
              newPassword: this.passwordForm.password,
              newPasswordCheck: this.passwordForm.passwordRepeat,
              token: this.token,
              email: this.email
            })
            .then(
              () => {
                this.loading = false
                this.success = true
              },
              () => {
                this.loading = false
                this.failed = true
              }
            )
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-container {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}
.el-card {
  align-self: center;
  width: 100%;
}
</style>
