import { Api } from '@/config'
import Manual from '../models/Manual'
import Chapter from '../models/Chapter'
import Block from '../models/Block'
import Revision from '../models/Revision'
const state = {
  activeManual: null, // used on the detail pages etc. Gets reset on every re-visit to a organisation/{id}
  manualList: []
}

const actions = {
  all({ commit }) {
    return new Promise((resolve, reject) => {
      commit('resetManualList')
      Api.get('manuals/all').then(
        response => {
          if (response?.status === 200) {
            response.data.forEach(m => {
              commit('addManualToList', new Manual(m))
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  getByOrganisation({ commit }, organisationId) {
    return new Promise((resolve, reject) => {
      commit('resetManualList')
      Api.get(`organisations/${organisationId}/manuals`).then(
        response => {
          if (response?.status === 200) {
            response.data.forEach(m => {
              commit('addManualToList', new Manual(m))
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  addManual({ commit }, dto) {
    return new Promise((resolve, reject) => {
      Api.post('manuals', dto).then(
        response => {
          if (response && response.status === 200) {
            if (response.data?.is_template) {
              commit('template/addTemplateToList', new Manual(response.data), {
                root: true
              })
            } else {
              commit('addManualToList', new Manual(response.data))
            }
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  delete({ commit }, { id, is_template }) {
    return new Promise((resolve, reject) => {
      Api.delete(`manuals/${id}`).then(
        response => {
          if (response && response.status === 204) {
            if (is_template) {
              commit('template/removeTemplate', id, { root: true })
            } else {
              commit('removeManual', id)
            }
            resolve()
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  getSingleByOrganisation({ commit }, { manualId, organisationId }) {
    return new Promise((resolve, reject) => {
      commit('setActiveManual', null)
      Api.get(`organisations/${organisationId}/manuals/${manualId}`).then(
        response => {
          if (response?.status === 200) {
            commit('setActiveManual', new Manual(response.data))
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updateManual({ commit }, { manualId, dto }) {
    return new Promise((resolve, reject) => {
      Api.put(`manuals/${manualId}`, dto).then(
        response => {
          if (response && response.status === 204) {
            if (dto.is_template) {
              commit(
                'template/updateTemplateInList',
                { template: dto, templateId: manualId },
                { root: true }
              )
            } else {
              commit('updateManualInList', { manual: dto, manualId })
            }
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  orderChapters({ commit }, { manualId, order }) {
    return new Promise((resolve, reject) => {
      Api.put(`/manuals/${manualId}/chapters/orderChapters`, { order }).then(
        response => {
          if (response && response.status === 204) {
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  addChapter({ commit, state, dispatch }, { manualId, payload }) {
    return new Promise((resolve, reject) => {
      Api.post(`manuals/${manualId}/chapters`, payload).then(
        response => {
          if (response && response.status === 200) {
            commit('addChapterToActiveManual', {
              manualId,
              chapter: new Chapter(response.data),
              indexToPutChapter: payload.order
            })

            // we're putting the chapter on the index it wants to be in the activeManual.chapters array
            // now we have to re-order those positions with a correct number and save that to the DB
            const orderingPayload = {}
            let count = 1
            state.activeManual.chapters.forEach(c => {
              orderingPayload[c.id] = count
              count++
            })

            dispatch('orderChapters', {
              manualId,
              order: orderingPayload
            }).then(
              () => {
                resolve()
              },
              error => {
                reject(error.response.data.message)
              }
            )
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  deleteChapter({ commit }, { chapterId, manualId }) {
    return new Promise((resolve, reject) => {
      Api.delete(`manuals/${manualId}/chapters/${chapterId}`).then(
        response => {
          if (response?.status === 204) {
            commit('removeChapterFromActiveManual', chapterId)
            resolve()
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  updateChapter({ commit }, { manualId, chapterId, payload }) {
    return new Promise((resolve, reject) => {
      Api.put(`manuals/${manualId}/chapters/${chapterId}`, payload).then(
        response => {
          if (response && response.status === 204) {
            commit('updateChapterInActiveManual', { chapterId, payload })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  orderBlocks({ commit }, { manualId, chapterId, order }) {
    return new Promise((resolve, reject) => {
      Api.put(`manuals/${manualId}/chapters/${chapterId}/blocks/orderBlocks`, {
        order
      }).then(
        response => {
          if (response && response.status === 204) {
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  addBlock({ commit, dispatch }, { manualId, chapterId, payload }) {
    // automatically add the block Title as the revision title
    payload.revision = { title: payload.title }
    return new Promise((resolve, reject) => {
      Api.post(
        `manuals/${manualId}/chapters/${chapterId}/blocks`,
        payload
      ).then(
        response => {
          if (response && response.status === 200) {
            commit('addBlockToChapterInActiveManual', {
              chapterId,
              block: new Block(response.data),
              blockPosition: payload.order
            })

            // update the order of all other blocks
            const orderingPayload = {}
            let count = 1
            const chapterWithBlocks = state.activeManual.chapters.find(
              c => c.id === chapterId
            )

            if (chapterWithBlocks.blocks.length > 1) {
              chapterWithBlocks.blocks.forEach(c => {
                orderingPayload[c.id] = count
                count++
              })
              dispatch('orderBlocks', {
                manualId: manualId,
                chapterId: chapterId,
                order: orderingPayload
              }).then(
                () => {
                  resolve()
                },
                error => {
                  reject(error.response.data.message)
                }
              )
            } else {
              // no need to orderBlocks since there's just 1
              resolve()
            }
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  deleteBlock({ commit }, { chapterId, blockId, manualId }) {
    return new Promise((resolve, reject) => {
      Api.delete(
        `manuals/${manualId}/chapters/${chapterId}/blocks/${blockId}`
      ).then(
        response => {
          if (response?.status === 204) {
            commit('removeBlockFromChapterFromActiveManual', {
              chapterId,
              blockId
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  updateBlock({ commit }, { blockId, chapterId, manualId, payload }) {
    return new Promise((resolve, reject) => {
      Api.put(
        `manuals/${manualId}/chapters/${chapterId}/blocks/${blockId}`,
        payload
      ).then(
        response => {
          if (response && response.status === 204) {
            commit('updateBlockInChapterInActiveManual', {
              chapterId,
              blockId,
              payload
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  updateRevision(
    { commit },
    { revisionId, manualId, blockId, chapterId, payload }
  ) {
    return new Promise((resolve, reject) => {
      Api.put(
        `manuals/${manualId}/chapters/${chapterId}/blocks/${blockId}/revisions/${revisionId}`,
        payload
      ).then(
        response => {
          if (response && response.status === 204) {
            commit('updateRevisionInActiveManual', {
              revisionId,
              manualId,
              blockId,
              chapterId,
              payload
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  copyRevision({ commit }, { revisionId, manualId, blockId, chapterId }) {
    return new Promise((resolve, reject) => {
      Api.post(
        `manuals/${manualId}/chapters/${chapterId}/blocks/${blockId}/revisions/${revisionId}/copy`
      ).then(
        response => {
          if (response && response.status === 200) {
            commit('addCopiedRevisionToActiveManual', {
              manualId,
              blockId,
              chapterId,
              payload: new Revision(response.data)
            })
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  toggleDocumentStatus(
    { commit },
    { manualId, chapterId, blockId, documentId, newState }
  ) {
    return new Promise((resolve, reject) => {
      Api.put(
        `manuals/${manualId}/chapters/${chapterId}/blocks/${blockId}/documents/${documentId}/toggleActive`
      ).then(
        response => {
          if (response && response.status === 204) {
            commit('updateDocumentState', {
              manualId,
              chapterId,
              blockId,
              documentId,
              newState
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  removeDocument({ commit }, { manualId, chapterId, blockId, documentId }) {
    return new Promise((resolve, reject) => {
      Api.delete(
        `manuals/${manualId}/chapters/${chapterId}/blocks/${blockId}/documents/${documentId}`
      ).then(
        response => {
          if (response && response.status === 204) {
            commit('removeDocumentFromActiveManual', {
              manualId,
              chapterId,
              blockId,
              documentId
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  }
}

const mutations = {
  addManualToList(state, manual) {
    state.manualList.push(manual)
  },
  resetManualList(state) {
    state.manualList = []
  },
  removeManual(state, manualId) {
    const manualIndex = state.manualList.findIndex(u => u.id === manualId)
    if (manualIndex >= 0) {
      state.manualList.splice(manualIndex, 1)
    }
  },
  setActiveManual(state, manual) {
    state.activeManual = manual
  },
  updateManualInList(state, { manual, manualId }) {
    const manualToUpdate = state.manualList.find(m => m.id === manualId)
    if (manualToUpdate) {
      manualToUpdate.setData(manual)
    }
  },
  updateChapterOrder(state, chapters) {
    state.activeManual.chapters = chapters

    // aside from switching the chapter array around; we should also update the order number in their model
    let count = 1
    chapters.forEach(chapter => {
      chapter.order = count
      count++
    })
  },
  addChapterToActiveManual(state, { chapter, indexToPutChapter }) {
    state.activeManual.chapters.splice(indexToPutChapter, 0, chapter)
  },
  removeChapterFromActiveManual(state, chapterId) {
    const chapterIndex = state.activeManual.chapters.findIndex(
      c => c.id === chapterId
    )
    if (chapterIndex >= 0) {
      state.activeManual.chapters.splice(chapterIndex, 1)
    }
  },
  updateChapterInActiveManual(state, { chapterId, payload }) {
    const chapter = state.activeManual.chapters.find(c => c.id === chapterId)
    if (chapter) {
      chapter.setData(payload)
    }
  },
  updateBlocksOrderInChapter(state, { chapterId, blocks }) {
    const chapter = state.activeManual.chapters.find(c => c.id === chapterId)
    if (chapter) {
      chapter.blocks = blocks
      // aside from switching the blocks array around; we should also update the order number in their model
      let count = 1
      blocks.forEach(block => {
        block.order = count
        count++
      })
    }
  },
  addBlockToChapterInActiveManual(state, { chapterId, block, blockPosition }) {
    const chapter = state.activeManual.chapters.find(c => c.id === chapterId)
    if (chapter) {
      chapter.blocks.splice(blockPosition, 0, block)
    }
  },
  removeBlockFromChapterFromActiveManual(state, { chapterId, blockId }) {
    const chapter = state.activeManual.chapters.find(c => c.id === chapterId)
    if (chapter) {
      const blockIndex = chapter.blocks.findIndex(b => b.id === blockId)
      if (blockIndex >= 0) {
        chapter.blocks.splice(blockIndex, 1)
      }
    }
  },
  updateBlockInChapterInActiveManual(state, { chapterId, blockId, payload }) {
    const chapter = state.activeManual.chapters.find(
      chapter => chapter.id === chapterId
    )
    if (chapter) {
      const block = chapter.blocks.find(b => b.id === blockId)
      if (block) {
        block.setData(payload)
      }
    }
  },
  updateRevisionInActiveManual(
    state,
    { revisionId, blockId, chapterId, payload }
  ) {
    const chapter = state.activeManual.chapters.find(
      chapter => chapter.id === chapterId
    )
    if (chapter) {
      const block = chapter.blocks.find(b => b.id === blockId)
      if (block) {
        const revision = block.revisions.find(r => r.id === revisionId)
        if (revision) {
          revision.setData(payload)
        }
      }
    }
  },
  addCopiedRevisionToActiveManual(state, { blockId, chapterId, payload }) {
    const chapter = state.activeManual.chapters.find(
      chapter => chapter.id === chapterId
    )
    if (chapter) {
      const block = chapter.blocks.find(b => b.id === blockId)
      if (block) {
        // set all revisions' active status to false before adding this new active revision
        block.revisions.forEach(rev => {
          rev.is_active = false
        })
        block.revisions.unshift(payload)
      }
    }
  },
  addDocumentToBlockInActiveManual(state, { document, chapterId, blockId }) {
    const chapter = state.activeManual.chapters.find(
      chapter => chapter.id === chapterId
    )
    if (chapter) {
      const block = chapter.blocks.find(b => b.id === blockId)
      if (block) {
        block.documents.unshift(document)
      }
    }
  },
  updateDocumentState(state, { chapterId, blockId, documentId, newState }) {
    const chapter = state.activeManual.chapters.find(
      chapter => chapter.id === chapterId
    )
    if (chapter) {
      const block = chapter.blocks.find(b => b.id === blockId)
      if (block) {
        const document = block.documents.find(d => d.id === documentId)
        if (document) {
          document.is_active = newState
        }
      }
    }
  },
  removeDocumentFromActiveManual(
    state,
    { chapterId, blockId, documentId, newState }
  ) {
    const chapter = state.activeManual.chapters.find(
      chapter => chapter.id === chapterId
    )
    if (chapter) {
      const block = chapter.blocks.find(b => b.id === blockId)
      if (block) {
        const documentIndex = block.documents.findIndex(
          d => d.id === documentId
        )
        if (documentIndex >= 0) {
          block.documents.splice(documentIndex, 1)
        }
      }
    }
  }
}

const getters = {
  getChapterFromActiveManual: state => chapterId => {
    return state.activeManual.chapters.find(c => c.id === parseInt(chapterId))
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
