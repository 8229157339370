<template>
  <el-form :rules="rules" ref="form" :model="form" v-loading="saving">
    <el-row>
      <el-col :span="11">
        <el-form-item prop="first_name" label="Naam">
          <el-input type="text" v-model="form.first_name" />
        </el-form-item>
      </el-col>
      <el-col :span="2" v-html="'&nbsp;'"></el-col>
      <el-col :span="11">
        <el-form-item prop="last_name" label="Achternaam">
          <el-input type="text" v-model="form.last_name" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16">
        <el-form-item prop="email" label="E-mailadres">
          <el-input type="text" v-model="form.email" />
          <small class="el-form__help-text">Moet uniek zijn</small>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16">
        <el-form-item prop="role" label="Rol">
          <el-select v-model="form.role" placeholder="Selecteer een rol" v-if="form.can_set_role" style="width: 100%;">
            <el-option
              v-for="role in orderedAvailableRoles"
              :key="role.id"
              :label="role.readableName"
              :value="role.name"
            ></el-option>
          </el-select>
          <span class="el-input el-form__help-text" v-if="!form.can_set_role">{{ form.roleReadable }} </span>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item>
          <el-button @click.prevent="submitForm" type="primary"
            >Opslaan</el-button
          >
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'UserForm',
  props: {
    existingFormData: {
      type: Object,
      required: false
    },
    saving: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('role', ['availableRoles']),
    ...mapState('user', ['user']),
    orderedAvailableRoles() {
      if (this.availableRoles !== undefined) {
        const order = ['auditor', 'manualViewerBasic', 'manualViewer', 'administrator', 'manualManager']

        return this.availableRoles.slice().sort(function(a, b) {
          return order.indexOf(a.name) - order.indexOf(b.name)
        })
      }
      return []
    }
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        roleReadable: '',
        can_set_role: true
      },
      rules: {
        first_name: [
          {
            required: true,
            message: 'De naam mag niet leeg zijn',
            trigger: ['blur', 'change']
          }
        ],
        last_name: [
          {
            required: true,
            message: 'De achternaam mag niet leeg zijn',
            trigger: ['blur', 'change']
          }
        ],
        role: [
          {
            required: !this.existingFormData,
            message: 'De rol mag niet leeg zijn',
            trigger: ['blur', 'change']
          }
        ],
        email: [
          {
            required: true,
            message: 'Het e-maildres mag niet leeg zijn',
            trigger: ['blur', 'change']
          },
          {
            type: 'email',
            message:
              'Het e-mailadres is onjuist geformatteerd (name@email.com)',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  mounted() {
    if (!this.availableRoles.length) {
      this.loading = true
      this.$store
        .dispatch('role/getAvailableRoles')
        .then(
          () => {
            //
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }

    this.$refs.form.resetFields()

    if (this.existingFormData) {
      this.setExistingData()
    }
  },
  methods: {
    setExistingData() {
      this.form.first_name = this.existingFormData.first_name
      this.form.last_name = this.existingFormData.last_name
      this.form.email = this.existingFormData.email
      this.form.roleReadable = this.existingFormData.roles[0].readableName
      this.form.role = this.existingFormData.roles[0].name
      this.form.can_set_role = this.user.isSuperAdmin
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submitted', this.form)
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>
