import moment from 'moment'
moment.locale('nl')
export default class Revision {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const {
      block_id,
      created_at,
      description,
      id,
      is_active,
      title,
      updated_at
    } = data

    if (block_id) {
      this.block_id = block_id
    }

    if (created_at) {
      this.created_at = created_at
    }

    this.description = description

    if (id) {
      this.id = id
    }

    if (is_active) {
      this.is_active = true
    }

    if (title) {
      this.title = title
    }

    if (updated_at) {
      this.updated_at = updated_at
    }
  }

  get createdDateFormatted() {
    const dateUtc = moment.utc(this.created_at).toDate()
    return `${moment(dateUtc)
      .local()
      .format('DD MMMM YYYY | H:mm')}`
  }

  get updatedDateFormatted() {
    const dateUtc = moment.utc(this.updated_at).toDate()
    return `${moment(dateUtc)
      .local()
      .format('DD MMMM YYYY | H:mm')}`
  }

  id = null
  title = ''
  description = ''

  block_id = null
  is_active = false
  updated_at = ''
  created_at = ''
}
