<template>
  <el-container class="container" v-loading="loading">
    <template v-if="user || routeNameBelongsToRouteView">
      <el-header height="auto" class="header">
        <router-link :to="{ name: 'Home' }">
          <img src="logo.svg" alt="Logo" class="header__logo" />
        </router-link>
        <navbar-main-menu
          v-if="
            (user && activeOrganisation && activeOrganisation.is_active) ||
              (user && user.isSuperAdmin)
          "
          class="header__navigation"
        />
      </el-header>
      <el-main :class="$route.name.toLowerCase()">
        <router-view
          v-if="organisations.length || routeNameBelongsToRouteView"
        />
      </el-main>
    </template>
    <user-login v-else />
  </el-container>
</template>
<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { privacyStatementUrl } from '@/config'
import UserLogin from '@/components/User/Login'
import NavbarMainMenu from '@/components/Navbar/MainMenu'
export default {
  name: 'App',
  components: {
    UserLogin,
    NavbarMainMenu
  },
  data() {
    return {
      loading: false,
      tokenAliveTimer: null,
      warningExpirationShown: false
    }
  },
  watch: {
    $route(to) {
      this.setTitle()
      this.checkPermissions()
      this.checkForPrivacyStatement()
    },
    user(u) {
      if (!u) {
        this.clearData()

        if (this.$router.name !== 'Home') {
          this.$router.push({ name: 'Home' })
        }
      } else {
        this.checkForPrivacyStatement()
        this.checkPermissions()
        if (this.organisations.length === 0) {
          this.loading = true
          const action = this.user.isSuperAdmin ? 'getAll' : 'getSingle'
          const payload = this.user.organisation_id
            ? this.user.organisation_id
            : null
          this.$store
            .dispatch(`organisation/${action}`, payload)
            .then(
              () => {
                //
              },
              error => {
                this.$notify({
                  title: 'Er is iets misgegaan. De melding is:',
                  message: error,
                  type: 'error'
                })
              }
            )
            .finally(() => {
              this.loading = false
            })
        }
      }
    }
  },
  mounted() {
    // Load the Smartsupp app, so it can be used later
    window._smartsupp = {}
    window._smartsupp.key = '5ea82816eabd2242964fddbf545325a52d695135'
    const smartsupp = document.createElement('script')
    smartsupp.setAttribute('src', 'https://www.smartsuppchat.com/loader.js?')
    document.head.appendChild(smartsupp)

    this.setTitle()
    this.checkPermissions()
    this.checkForPrivacyStatement()
    // app.vue is our shell so the tokenalivetimer should run here because this component will never be destroyed when navigating
    // so check here if a user is still logged in, if not; emit this to the login.vue to re-check the user's signin
    bus.$on('startTokenAliveTimer', () => {
      /*
      This timer is set whenever a user is logged in. It check if the expirationTimestamp in the localStorage
      is exceeded by the NOW timestamp.

      If such is the case, logout the user

      A watch in the App.vue will notice when the user is set to null and will redirect the router to the login page
      */
      const expirationTimestamp = parseInt(
        localStorage.getItem('kysExpirationTimestamp')
      )

      if (!this.tokenAliveTimer && this.user && expirationTimestamp) {
        this.tokenAliveTimer = setInterval(() => {
          const now = new Date().getTime()

          // Check if the token is about to expire:
          if ((expirationTimestamp - now) < (5 /* minuten */ * 60 * 1000) && !this.warningExpirationShown) {
            this.warningExpirationShown = true
            bus.$emit('userSessionIsExpiring', parseInt((expirationTimestamp - now) / 1000 / 60))
          }

          if (now >= expirationTimestamp) {
            // reset this timer
            clearInterval(this.tokenAliveTimer)
            this.tokenAliveTimer = null

            bus.$emit('userSessionIsExpired')
          }
        }, 5000)
      }
    })
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('organisation', ['organisations']),
    activeOrganisation() {
      if (this.user && this.user.organisation_id) {
        return this.organisations.find(
          o => o.id === parseInt(this.user.organisation_id)
        )
      }

      return []
    },
    routeNameBelongsToRouteView() {
      // a list of routes that do not require a user but still have a routeview
      const routeViewsWithoutUserNeccesity = ['UserResetPassword']
      return routeViewsWithoutUserNeccesity.find(n => n === this.$route.name)
    }
  },
  methods: {
    setTitle() {
      const title = this.$route.meta.title
      if (title) {
        document.title = title
      }
    },
    checkOrganisationActive() {
      if (this.activeOrganisation && this.user) {
        if (
          !this.user.isSuperAdmin &&
          !this.activeOrganisation.is_active &&
          this.$route.name !== 'unavailable'
        ) {
          this.$router.push({ name: 'unavailable' })
        }
      }
    },
    clearData() {
      this.$store.commit('organisation/resetOrganisations')
      this.$store.commit('manual/resetManualList')
      this.$store.commit('template/resetTemplateList')
      this.$store.commit('role/resetAvailableRoles')
      this.$store.commit('user/setUser', null)
      this.$store.commit('manual/setActiveManual', null)
    },
    checkForPrivacyStatement() {
      if (this.user && !this.user.hasAcceptedPrivacyStatement) {
        this.$swal({
          title: 'Welkom bij het KYS Handboekensysteem',
          html: `Voor het gebruik dient u (eenmalig) akkoord te gaan met onze <a href="${privacyStatementUrl}" target="_blank">privacy-verklaring</a>`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Niet akkoord gaan',
          confirmButtonText: 'Ik ga akkoord'
        }).then(result => {
          if (result.value) {
            this.loading = true
            this.$store
              .dispatch('user/agreeToPrivacyStatement', 1)
              .then(
                () => {
                  this.$swal.fire(
                    'Akkoord!',
                    'Bedankt voor het akkoord gaan met de privacy-verklaring.',
                    'success'
                  )
                },
                error => {
                  this.$notify({
                    title: 'Er is iets misgegaan. De melding is:',
                    message: error,
                    type: 'error'
                  })
                }
              )
              .finally(() => {
                this.loading = false
              })
          } else {
            // the user didn't accept. Log him out.
            this.$store.commit('user/logOut')
          }
        })
      }
    },
    checkPermissions() {
      this.checkOrganisationActive()
      if (this.user && this.$route?.meta?.permissions) {
        if (!this.$route.meta.permissions.includes(this.user.roles[0].name)) {
          // the rolename of the current user is not in the permissions array
          this.$notify({
            title: 'Er is iets misgegaan. De melding is:',
            message:
              'Je hebt geen toegang tot de pagina die je probeerde te benaderen.',
            type: 'error'
          })
          if (this.$router.name !== 'Home') {
            this.$router.push({ name: 'Home' })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
// essentials
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700&display=swap');
@import '@/styles/reset.scss';
@import '@/styles/breakpoints.scss';
@import '@/styles/type.scss';
@import '~element-ui/packages/theme-chalk/src/index';

// components
@import '@/styles/form.scss';
@import '@/styles/manual.scss';
@import '@/styles/block.scss';
@import '@/styles/list.scss';
@import '@/styles/dialog.scss';
@import '@/styles/line-add.scss';
@import '@/styles/revision.scss';
@import '@/styles/documents.scss';
@import '@/styles/table-of-contents.scss';
@import '@/styles/html-content.scss';
@import '@/styles/is--empty.scss';

body {
  background-color: $--background-color-base;
}

body {
  font-size: $--font-size-base;
  font-family: $--font-family;
}

img {
  max-width: 100%;
  height: auto;
}

.header {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 3;

  &__logo {
    height: 65px;
  }
}

.search-box {
  margin-bottom: 1.5rem;
}

.el-container.container {
  flex-direction: column;
}

.el-main {
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  overflow: initial;
}

.icon-button {
  border: 0;
  padding: 7px 5px;
  background: none;
  color: $--link-color;
  font-size: 17px;
  cursor: pointer;

  &--text &__icon {
    margin-right: 0.5rem;
  }

  &:hover {
    color: black;
  }
}

.has--clickable-row {
  cursor: pointer;
}
.draggable {
  &__dragger {
    cursor: move;

    &:hover {
      background: #ebf1f2;
    }
  }

  &--ghost {
    background: #ebf1f2;
  }
}

.el-button--default {
  border-color: $--color-primary;
  color: $--color-primary;
}

.el-button__icon {
  font-size: 20px;
}

.el-table {
  &,
  &__body-wrapper,
  .cell {
    overflow: initial;
  }
}

.el-table--striped .el-table__body tr.el-table__row--striped {
  background: $--background-color-base;

  &:hover {
    background: $--table-row-hover-background-color;
  }
}

.el-table + .el-button {
  margin-top: 1.5rem;
}

.el-table th,
.el-table td {
  padding: 1px 0;
}
</style>
