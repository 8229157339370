<template>
  <div>
    <h1>Handboeken overzicht</h1>
    <el-input
      type="search"
      v-model="searchQuery"
      suffix-icon="el-icon-search"
      class="search-box"
      :placeholder="
        `Zoek op Titel${user.isSuperAdmin ? ' of organisatienaam' : ''}`
      "
    />
    <el-button
      type="primary"
      @click.prevent="addDialogVisible = true"
      v-if="user.isSuperAdmin"
    >
      Voeg een handboek toe
    </el-button>
    <el-table
      stripe
      :data="manualListQueried"
      class="has--clickable-row"
      v-loading="loading"
      @row-click="showManual"
      :default-sort="{ prop: 'version_date', order: 'descending' }"
    >
      <el-table-column
        sortable
        prop="project_number"
        label="Project"
        width="100"
      ></el-table-column>
      <el-table-column sortable prop="title" label="Titel" width="300"></el-table-column>
      <el-table-column
        sortable
        prop="organisation.name"
        label="Organisatie"
      ></el-table-column>
      <!--<el-table-column
        sortable
        prop="user.name"
        label="Auteur"
        width="150"
      ></el-table-column>-->
      <el-table-column
        sortable
        prop="version"
        label="Versie"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        sortable
        prop="version_date"
        label="Laatst gewijzigd"
        width="160"
      >
        <template slot-scope="scope">
          {{ scope.row.versionDateFormatted }}
        </template>
      </el-table-column>
      <el-table-column width="75" align="right" v-if="user.canEditManual">
        <template slot-scope="scope">
          <context-menu>
            <li style="width:325px;">
              <router-link
                v-if="user.isSuperAdmin"
                class="icon-button icon-button--text"
                :to="{
                  name: 'ManualView',
                  params: {
                    organisationId: scope.row.organisation.id,
                    manualId: scope.row.id
                  }
                }"
              >
                <i class="icon-button__icon fal fa-search"></i>
                Handboek bekijken
              </router-link>
            </li>
            <li style="width:325px;">
              <router-link
                v-if="user.isSuperAdmin"
                class="icon-button icon-button--text"
                :to="{
                  name: 'ManualEdit',
                  params: {
                    organisationId: scope.row.organisation.id,
                    manualId: scope.row.id
                  }
                }"
              >
                <i class="icon-button__icon fal fa-pen"></i>
                Gegevens handboek wijzigen
              </router-link>
            </li>
            <li style="width:325px;">
              <router-link
                class="icon-button icon-button--text"
                :to="{
                  name: 'ManualEditor',
                  params: {
                    manualId: scope.row.id,
                    organisationId:
                      scope.row.organisation.id || user.organisation_id
                  }
                }"
              >
                <i class="icon-button__icon fal fa-book"></i>
                Inhoud handboek wijzigen
              </router-link>
            </li>
            <li style="width:325px;">
              <a
                v-if="user.isSuperAdmin && scope.row.id !== 1"
                href="#"
                class="icon-button icon-button--text"
                @click.prevent.stop="deleteManual(scope.row.id)"
              >
                <i class="icon-button__icon fal fa-trash-alt"></i>
                Handboek verwijderen
              </a>
            </li>
          </context-menu>
        </template>
      </el-table-column>
    </el-table>
    <el-button
      type="primary"
      @click.prevent="addDialogVisible = true"
      v-if="user.isSuperAdmin"
    >
      Voeg een handboek toe
    </el-button>
    <el-dialog
      title="Handboek toevoegen"
      :visible.sync="addDialogVisible"
      v-if="user.isSuperAdmin"
    >
      <manual-form @submitted="addManual" @cancel="closeForm" ref="manualForm" :saving="loading" />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ManualForm from '@/components/Manual/Form'
import ContextMenu from '@/components/Helpers/ContextMenu'
export default {
  name: 'Home',
  components: {
    ManualForm,
    ContextMenu
  },
  data() {
    return {
      loading: false,
      addDialogVisible: false,
      contextMenuOpen: null,
      searchQuery: ''
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('manual', ['manualList']),
    manualListQueried() {
      let defList = this.manualList

      defList = this.manualList.filter(item => {
        // Superadmin can search for organisation name.
        if (this.user.isSuperAdmin && item.organisation?.name) {
          if (
            item.organisation.name
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) >= 0 ||
            item.title.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >=
              0
          ) {
            return item
          }
        }

        if (
          item.title.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0
        ) {
          return item
        }
      })

      return defList
    }
  },
  mounted() {
    if (this.manualList.length === 0) {
      let action = ''
      let organisationId = false
      if (this.user.isSuperAdmin) {
        action = 'all'
      } else if (!this.user.isSuperAdmin && this.user.organisation_id) {
        action = 'getByOrganisation'
        organisationId = this.user.organisation_id
      }

      if (action) {
        this.loading = true
        this.$store
          .dispatch(`manual/${action}`, organisationId)
          .then(
            () => {
              //
            },
            error => {
              this.$notify({
                title:
                  'Er is iets misgegaan met het ophalen van de handboeken. De melding is:',
                message: error,
                type: 'error'
              })
            }
          )
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  methods: {
    showManual(m) {
      const organisationId = m.organisation.id
        ? m.organisation.id
        : this.user.organisation_id
      // if the user is a SuperAdmin, go to the Editor, otherwise go to the viewer
      if (this.user.isSuperAdmin) {
        this.$router.push({
          name: 'ManualEditor',
          params: { organisationId, manualId: m.id }
        })
      } else {
        this.$router.push({
          name: 'ManualView',
          params: { organisationId, manualId: m.id }
        })
      }
    },
    addManual(data) {
      let action = ''
      if (data.template_id) {
        // send to copy template to manual
        action = 'template/copyTemplateToManual'
      } else {
        // just POST a new manual
        action = 'manual/addManual'
      }
      this.loading = true
      this.$store
        .dispatch(action, data)
        .then(
          () => {
            this.$notify({
              title: 'Het handboek is toegevoegd',
              type: 'success'
            })
            this.closeForm()
          },
          error => {
            this.$notify({
              title:
                'Er is iets misgegaan met het opslaan van het handboek. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    closeForm() {
      this.addDialogVisible = false
      this.$refs.manualForm.clearForm()
    },
    deleteManual(id) {
      if (id) {
        this.$swal({
          title: 'Weet je het zeker?',
          html:
            '<strong>LET OP:</strong>Met deze actie worden ook alle hoofdstukken, elementen en documenten verwijderd.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Annuleren',
          confirmButtonText: 'Ik begrijp het. Verwijder dit handboek'
        }).then(result => {
          if (result.value) {
            this.loading = true
            this.$store
              .dispatch('manual/delete', { id, is_template: false })
              .then(
                () => {
                  this.$notify({
                    title: 'Het handboek is verwijderd',
                    type: 'success'
                  })
                },
                error => {
                  this.$notify({
                    title: 'Er is iets misgegaan. De melding is:',
                    message: error,
                    type: 'error'
                  })
                }
              )
              .finally(() => {
                this.loading = false
              })
          }
        })
      }
    }
  }
}
</script>
