<template>
  <div
    v-loading="loading"
    v-if="user.isSuperAdmin"
    class="line-add"
    :class="{ 'is--visible': addDialog }"
  >
    <div class="line-add__inner" v-if="addDialog == true">
      <block-form
        @submitted="addBlock"
        @cancelled="cancelAdd"
        :saving="loading"
      />
    </div>
    <div
      class="line-add__inner line-add__inner--buttons"
      @click.prevent="addDialog = true"
      v-else
    >
      <button class="line-add__button">
        <i class="fal fa-plus-circle line-add__icon"></i></button
      ><br />
      <span class="line-add__text">Voeg hier een element toe</span>
    </div>
  </div>
  <div v-else class="is--empty" />
</template>
<script>
import { mapState } from 'vuex'
import blockForm from '@/components/Manual/Editor/Block/Form' // we can re-use the chapterform because they're exactly the same
export default {
  name: 'EditorBlockAdd',
  props: {
    blockIndexReservation: {
      // the index in which the chapter should be pushed in the chapters array to define the new ordering of all chapters
      type: Number,
      required: true
    },
    manualId: {
      type: Number,
      required: true
    },
    chapterId: {
      type: Number,
      required: true
    }
  },
  components: {
    blockForm
  },
  data() {
    return {
      addDialog: false,
      loading: false
    }
  },
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    cancelAdd() {
      this.addDialog = false
    },
    addBlock(data) {
      this.loading = true
      data.order = this.blockIndexReservation // add order based on the prop
      this.$store
        .dispatch('manual/addBlock', {
          payload: data,
          chapterId: this.chapterId,
          manualId: this.manualId
        })
        .then(
          () => {
            this.addDialog = false
            this.$notify({
              title: 'Het element is toegevoegd',
              type: 'success'
            })
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
