import { Api, bearerTokenName, setBearerToken } from '@/config'
import User from '../models/User'

import { bus } from '../../main'
const state = {
  user: null,
  allUsers: []
}

const actions = {
  login({ dispatch }, { payload }) {
    return new Promise((resolve, reject) => {
      Api.post('auth/login', payload).then(
        response => {
          if (
            response?.status === 200 &&
            response?.data?.access_token &&
            response?.data?.token_type === 'bearer'
          ) {
            // get a timestamp of now + the MS of the expiration token to determine when to kill the user's login credentials
            let nowStamp = new Date().getTime()
            nowStamp += response.data.expires_in * 1000
            localStorage.setItem('kysExpirationTimestamp', nowStamp)
            dispatch('getUser', response.data.access_token).then(() => {
              resolve()
            })
          } else {
            const error = ['Het inloggen is mislukt']
            reject(error)
          }
        },
        error => {
          const errorMessage =
            error && error.response?.data?.error
              ? error.response.data.error
              : 'Het inloggen is mislukt. Probeer het opnieuw..'
          reject(errorMessage)
        }
      )
    })
  },
  refreshAuth({ dispatch }, token) {
    return new Promise((resolve, reject) => {
      let headers = null
      if (token && token !== null) {
        headers = {
          Authorization: `Bearer ${token}`
        }
      }
      Api.post('auth/refresh', null, { headers }).then(
        response => {
          if (
            response?.status === 200 &&
            response?.data?.access_token &&
            response?.data?.token_type === 'bearer'
          ) {
            // get a timestamp of now + the MS of the expiration token to determine when to kill the user's login credentials
            let nowStamp = new Date().getTime()
            nowStamp += response.data.expires_in * 1000
            localStorage.setItem('kysExpirationTimestamp', nowStamp)
            dispatch('getUser', response.data.access_token).then(() => {
              resolve()
            })
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  getUser({ commit }, token) {
    setBearerToken(false) // just in case: Reset the interceptor
    return new Promise((resolve, reject) => {
      Api.get('auth/userinfo', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(
        response => {
          if (response && response.status === 200) {
            const user = new User(response.data)
            commit('setUser', user)
            localStorage.removeItem(bearerTokenName)
            localStorage.setItem(bearerTokenName, token)
            setBearerToken(token)

            bus.$emit('startTokenAliveTimer')

            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  updatePassword(_, { password, passwordRepeat }) {
    return new Promise((resolve, reject) => {
      Api.put('profile/password', {
        password,
        password_confirmation: passwordRepeat
      }).then(
        response => {
          if (response) {
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  agreeToPrivacyStatement({ commit }, status) {
    return new Promise((resolve, reject) => {
      Api.put('profile/agree-to-privacy-statement', {
        accepted_privacy_statement: status
      }).then(
        response => {
          if (response && response.status === 200) {
            commit(
              'updateUserPrivacyStatementStatus',
              response.data.accepted_privacy_statement
            )
            resolve()
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  sendPasswordResetEmail({ commit }, { email }) {
    return new Promise((resolve, reject) => {
      Api.get(`auth/forgot?email=${email}`).then(
        response => {
          if (response?.status === 200) {
            resolve(response.data.message)
          }
        },
        error => {
          reject(error.response.data.error)
        }
      )
    })
  },
  resetPassword(_, { newPassword, newPasswordCheck, token, email }) {
    return new Promise((resolve, reject) => {
      Api.put('auth/reset', {
        password: newPassword,
        password_confirmation: newPasswordCheck,
        token,
        email
      }).then(
        response => {
          if (response && response.status === 204) {
            resolve()
          }
        },
        error => {
          reject(error.response.data.error)
        }
      )
    })
  }
}

const mutations = {
  updateProfile(state, dto) {
    if (state.user) {
      state.user.setData(dto)
    }
  },
  setUser(state, user) {
    state.user = user
  },
  logOut(state) {
    state.user = null

    localStorage.removeItem(bearerTokenName)
    localStorage.removeItem('kysExpirationTimestamp')

    setBearerToken(null)
  },
  updateUserPrivacyStatementStatus(state, status) {
    if (state.user) {
      state.user.accepted_privacy_statement = status
    }
  }
}

const getters = {}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
