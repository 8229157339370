<template>
  <el-form v-loading='saving'>
    <el-form-item prop='title' label='Titel'>
      <el-input type='text' v-model='form.title' />
    </el-form-item>
    <el-form-item class='html-editor'>
      <editor
        v-model='form.description'
        :init='tinyMceSettings'
      />
    </el-form-item>
    <el-form-item>
      <el-button type='primary' @click.prevent='submitForm'>Opslaan</el-button>
      <el-button type='link' @click.prevent='cancelForm'>Annuleren</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { Api } from '@/config'

import 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'

import 'tinymce/themes/silver'
import 'tinymce/icons/default'

import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/code'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/plugins/image'

import { mapState } from 'vuex'

export default {
  name: 'RevisionForm',
  props: {
    revision: {
      type: Object,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Editor
  },
  computed: {
    ...mapState('manual', ['activeManual'])
  },
  data() {
    return {
      form: {
        title: '',
        description: ''
      },
      tinyMceSettings: {
        language_url: '/tinymce/langs/nl.js',
        language: 'nl',
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.min.css',
        height: 500,
        menubar: false,
        branding: false,
        elementpath: false,
        table_sizing_mode: 'relative',
        table_use_colgroups: true,
        images_upload_handler: this.handleImageUpload,
        plugins: [
          'advlist autolink lists link image anchor',
          'visualblocks',
          'table paste'
        ],
        toolbar: `image table | undo redo | formatselect | bold italic underline strikethrough backcolor forecolor |
           alignleft aligncenter alignright alignjustify
           bullist numlist`,
        contextmenu: 'link image table paste',
        /* In the Editor, paragraphs have margins. This is annoying in tables. to prevent this, set the margin to 0 in table cells */
        content_style: 'h1,h2,h3,h4,h5, strong { color: #007c8f; } td p { margin: 0px;}'
      }
    }
  },
  mounted() {
    this.copyContents()
  },
  methods: {
    copyContents() {
      this.form.title = this.revision.title
      this.form.description = this.revision.description
    },
    submitForm() {
      this.$emit('submitted', this.form)
    },
    cancelForm() {
      this.$emit('cancelled')
    },
    handleImageUpload(blobInfo, success, failure, progress) {
      const formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())
      Api.post('images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress(e) {
          progress((e.loaded / e.total) * 100)
        }
      }).then(
        response => {
          if (response.status === 200) {
            success(response.data.url)
          }
        },
        error => {
          const theError = error?.data
            ? error.data
            : 'Er heeft zich een onbekend probleem voorgedaan'
          failure(theError)
        }
      )
    }
  }
}
</script>
<style lang='scss'>
// .quillWrapper .ql-editor {
//   min-height: 400px;
//   max-height: 80vh; // never make the editor higher than 80% of the VH. This way the editor will always fit entirely in the screen (With room to spare)
// }
.suggestions {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px rgba(black, 0.4);
  padding: 0;
  margin: 0;
  z-index: 999999;
  max-height: 185px;
  width: 275px;
  overflow: auto;

  &__item {
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;

    &:hover,
    &.is--targeted {
      background-color: darken(#ccc, 1);
    }
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 6px;
  }
}

.mce-content-body td p {
  padding: 0;
  margin: 0 !important;
}

.el-autocomplete-suggestion .el-autocomplete-suggestion__list > li {
  display: flex;
  padding: 0 10px;

  .icon {
    margin-right: 6px;
  }

  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    overflow: hidden;
  }
}
</style>
