<template>
  <div class="manual" v-if="activeManual" v-loading="loading">
    <aside
      class="manual__sidebar table-of-contents"
      :class="{ 'is--open': tocOpen }"
    >
      <div class="table-of-contents__heading" @click="tocOpen = !tocOpen">
        <span><i class="table-of-contents__icon fal fa-book"></i>Inhoud</span>
        <i
          class="fa table-of-contents__icon"
          :class="{ 'fa-times': tocOpen, 'fa-chevron-right': !tocOpen }"
        ></i>
      </div>
      <div class="table-of-contents__tree" v-show="tocOpen">
        <el-tree
          :data="tableOfContents"
          @node-click="tocNodeClicked"
          icon-class="fa fa-chevron-down el-tree-node__icon"
        >
        </el-tree>
      </div>
    </aside>
    <div class="manual__body" ref="manualBody">
      <div class="manual__heading">
        <h1 class="manual__title">
          {{ activeManual.title }}
          <router-link
            v-if="
              user.isSuperAdmin || (user.canEditManual && activeManual.id !== 1)
            "
            class="icon-button icon-button--text"
            :to="{
              name: 'ManualEditor',
              params: {
                manualId: activeManual.id,
                organisationId: this.$route.params.organisationId
              }
            }"
          >
            <i class="icon-button__icon fal fa-edit"></i>Bewerk
          </router-link>
        </h1>
        <el-button @click.prevent="downloadPdf" v-loading="downloading" v-if="user.canDownloadManual">
          <i class="el-button__icon fal fa-file-download"></i> Download handboek
        </el-button>
      </div>
      <div
        class="chapter"
        v-for="(chapter, chapterIndex) in activeManual.chapters"
        :key="chapterIndex"
        :ref="`chapter-${chapter.id}`"
        :id="`chapter-${chapter.id}`"
      >
        <h1 class="chapter__title">{{ chapter.title }}</h1>
        <div
          v-for="(block, blockIndex) in chapter.blocks"
          :key="blockIndex"
          :ref="`element-${block.id}`"
          class="chapter__block chapter-block"
        >
          <div class="chapter-block__heading">
            <h2 class="chapter-block__title">{{ block.title }}</h2>
            <div class="chapter-block__metadata">
              <div v-if="block.element_type && block.element_code">
                {{ block.element_type }}: {{ block.element_code }}
              </div>

              Versie {{ block.version }}
              <span class="chapter-block__metadata__divider"></span>
              {{ block.versionDateFormatted }}
            </div>
          </div>
          <html-content
            class="chapter-block__content"
            :key="blockIndex"
            v-if="block.activeRevision"
            :content="block.activeRevision.description"
            :manualId="activeManual.id"
            :chapterId="chapter.id"
            @internalLinkClicked="scrollToElement"
          ></html-content>
          <div v-if="block.activeDocuments.length" class="documents">
            <h3 class="documents__title">Documenten van "{{ block.element_code }} {{ block.title }}"</h3>
            <ul v-if="block.activeDocuments.length" class="list documents__list">
              <li
                class="list__item"
                v-for="(document, index) in block.documents"
                :key="index"
                :class="{ 'text--muted': !document.is_active }"
              >
                <a :href="document.url" target="_blank" v-if="document.is_active">
                  <i class="fa fa-file-download list__icon"></i>
                  {{ document.filename }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Api } from '@/config'
import HtmlContent from '@/components/Helpers/HtmlContent'
export default {
  name: 'ManualView',
  components: {
    HtmlContent
  },
  data() {
    return {
      loading: false,
      downloading: false,
      tocOpen: false
    }
  },
  computed: {
    ...mapState('manual', ['activeManual']),
    ...mapState('user', ['user']),
    tableOfContents() {
      const toc = []
      this.activeManual.chapters.forEach(chapter => {
        const chapterChildren = chapter.blocks.map(block => {
          return { label: block.element_code + ' ' + block.title, elementCode: block.id, type: 'block' }
        })

        const chapterRecord = {
          label: chapter.title,
          children: chapterChildren,
          elementCode: chapter.id,
          type: 'chapter'
        }

        toc.push(chapterRecord)
      })
      return toc
    }
  },
  mounted() {
    if (this.$route.params.manualId && this.$route.params.organisationId) {
      // If the current manual is the help manual, show the smartsupp chat if it is loaded
      if (this.$route.params.manualId === 1) {
        console.log('manual')
        if (window.smartsupp !== undefined) {
          console.log('smartsupp')
          window.smartsupp('chat:show')
        }
      }
      this.loading = true
      this.$store
        .dispatch('manual/getSingleByOrganisation', {
          manualId: this.$route.params.manualId,
          organisationId: this.$route.params.organisationId
        })
        .then(
          () => {
            //
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
          this.setTitle()
        })
    }
  },
  methods: {
    setTitle() {
      const title = this.activeManual
        ? this.activeManual.title
        : 'Niks gevonden'
      if (title) {
        document.title = title
      }
    },
    tocNodeClicked(data) {
      if (data.type === 'block' && data.elementCode) {
        this.scrollToElement(data.elementCode)
      }
    },
    scrollToElement(code) {
      if (this.$refs[`element-${code}`]) {
        this.$smoothScroll({
          scrollTo: this.$refs[`element-${code}`][0],
          duration: 1000,
          offset: -230,
          container: this.$refs.manualBody
        })
      }
    },
    downloadPdf() {
      this.downloading = true
      Api.get(
        `organisations/${this.$route.params.organisationId}/manuals/${this.activeManual.id}/pdf`,
        { responseType: 'blob' }
      )
        .then(response => {
          this.downloading = false
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', `${this.activeManual.title}.pdf`)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    }
  }
}
</script>

<style lang="scss">
.el-main.manualview {
  max-width: none;
  padding: 0 !important;
}
</style>
