<template>
  <div v-loading="loading">
    <h4>Documenten</h4>
    <ul v-if="documents.length" class="list">
      <li
        class="list__item"
        v-for="(document, index) in documents"
        :key="index"
        :class="{ 'text--muted': !document.is_active }"
      >
        <a :href="document.url" target="_blank">{{ document.filename }} | {{ document.createdDateFormatted }}</a>
        <button
          class="icon-button"
          @click.prevent="deleteDocument(document.id)"
        >
          <i class="fa fa-trash-alt" />
        </button>
        <el-tooltip
          class="el-table__option"
          effect="dark"
          :content="
            document.is_active
              ? 'De-activeer dit document'
              : 'Activeer dit document'
          "
          placement="top"
        >
          <button
            class="icon-button"
            @click.prevent="toggleActive(document.id, !document.is_active)"
          >
            <i class="fa fa-eye-slash" v-if="document.is_active" />
            <i class="fa fa-eye" v-else />
          </button>
        </el-tooltip>
      </li>
    </ul>
    <p v-else>Er zijn nog geen documenten aan dit element toegevoegd</p>
    <el-upload
      class="upload-demo"
      ref="upload"
      :headers="{ Authorization: `Bearer ${bearerToken}` }"
      :on-success="handleSuccess"
      :action="documentPostUrl"
      :show-file-list="false"
      :auto-upload="true"
    >
      <el-button slot="trigger" size="small" type="primary"
        >Nieuw document</el-button
      >
    </el-upload>
  </div>
</template>
<script>
import { baseURL, bearerTokenGlobal } from '@/config'
import Document from '@/store/models/Document'
export default {
  name: 'RevisionDocuments',
  props: {
    manualId: {
      type: Number,
      required: true
    },
    blockId: {
      type: Number,
      required: true
    },
    chapterId: {
      type: Number,
      required: true
    },
    documents: {
      type: Array
    }
  },
  data() {
    return {
      bearerToken: null,
      loading: false
    }
  },
  computed: {
    documentPostUrl() {
      return `${baseURL}manuals/${this.manualId}/chapters/${this.chapterId}/blocks/${this.blockId}/documents`
    }
  },
  mounted() {
    this.bearerToken = bearerTokenGlobal
  },
  methods: {
    deleteDocument(documentId) {
      this.$swal({
        title: 'Weet je het zeker?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuleren',
        confirmButtonText: 'Verwijder dit document'
      }).then(result => {
        if (result.value) {
          this.loading = true
          this.$store
            .dispatch('manual/removeDocument', {
              manualId: this.manualId,
              chapterId: this.chapterId,
              blockId: this.blockId,
              documentId
            })
            .then(
              () => {
                this.$notify({
                  title: 'Het document is verwijderd',
                  type: 'success'
                })
              },
              error => {
                this.$notify({
                  title: 'het document is niet verwijderd',
                  message: error,
                  type: 'error'
                })
              }
            )
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    toggleActive(documentId, newState) {
      this.loading = true
      this.$store
        .dispatch('manual/toggleDocumentStatus', {
          manualId: this.manualId,
          chapterId: this.chapterId,
          blockId: this.blockId,
          documentId,
          newState
        })
        .then(
          () => {
            this.$notify({
              title: 'Het document is geupdated',
              type: 'success'
            })
          },
          error => {
            this.$notify({
              title: 'het document is niet geupdated',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },

    handleSuccess(result) {
      if (result && result.id) {
        this.$store.commit('manual/addDocumentToBlockInActiveManual', {
          document: new Document(result),
          blockId: this.blockId,
          chapterId: this.chapterId
        })
      }
    }
  }
}
</script>
