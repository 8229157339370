import { Api } from '@/config'
import Organisation from '../models/Organisation'
import User from '../models/User'
const state = {
  organisations: []
}

const actions = {
  getAll({ commit }) {
    return new Promise((resolve, reject) => {
      Api.get('organisations/all').then(
        response => {
          if (response?.status === 200) {
            commit('resetOrganisations')
            response.data.forEach(org => {
              commit('addOrganisation', new Organisation(org))
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  add({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Api.post('organisations', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        response => {
          if (response && response.status === 200) {
            commit('addOrganisation', new Organisation(response.data))
            resolve(response.data.id)
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      Api.delete(`organisations/${id}`).then(
        response => {
          if (response && response.status === 204) {
            commit('removeOrganisation', id)
            resolve()
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  update({ commit }, { organisationId, payload }) {
    return new Promise((resolve, reject) => {
      Api.post(`organisations/${organisationId}/?_method=put`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        response => {
          if (response && response.status === 200) {
            commit('updateOrganisation', {
              payload: response.data,
              organisationId
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  getSingle({ commit }, organisationId) {
    return new Promise((resolve, reject) => {
      Api.get(`organisations/${organisationId}`).then(
        response => {
          if (response?.status === 200 && response.data) {
            commit('addOrganisation', new Organisation(response.data[0]))
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  addNewUser({ commit }, { organisationId, data }) {
    return new Promise((resolve, reject) => {
      Api.post(`organisations/${organisationId}/users`, data).then(
        response => {
          if (response && response.status === 200) {
            commit('addUserToOrganisation', {
              user: new User(response.data),
              organisationId
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data)
        }
      )
    })
  },
  updateUser({ commit }, { data, organisationId, userId }) {
    return new Promise((resolve, reject) => {
      Api.put(`organisations/${organisationId}/users/${userId}`, data).then(
        response => {
          if (response && response.status === 200) {
            commit('updateUserInOrganisation', {
              data: response.data,
              organisationId,
              userId
            })
            resolve()
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  },
  deleteUser({ commit }, { userId, organisationId }) {
    return new Promise((resolve, reject) => {
      Api.delete(`organisations/${organisationId}/users/${userId}`).then(
        response => {
          if (response?.status === 204) {
            commit('removeUserFromOrganisation', { userId, organisationId })
            resolve()
          }
        },
        error => {
          reject(error.response.data.error)
        }
      )
    })
  },
  toggleActiveStatus({ commit }, { id, is_active }) {
    return new Promise((resolve, reject) => {
      Api.put(`organisations/${id}/active`, { is_active: is_active }).then(
        response => {
          if (response && response.status === 204) {
            commit('toggleActiveStatus', { id, is_active })
            resolve()
          }
        },
        error => {
          reject(error.response.data.message)
        }
      )
    })
  }
}

const mutations = {
  addOrganisation(state, organisation) {
    state.organisations.push(organisation)
  },
  resetOrganisations(state) {
    state.organisations = []
  },
  removeOrganisation(state, organisationId) {
    const organisationIndex = state.organisations.findIndex(
      u => u.id === organisationId
    )
    if (organisationIndex >= 0) {
      state.organisations.splice(organisationIndex, 1)
    }
  },
  updateOrganisation(state, { payload, organisationId }) {
    const organisation = state.organisations.find(
      org => parseInt(org.id) === parseInt(organisationId)
    )
    if (organisation) {
      organisation.setData(payload)
    }
  },
  addUserToOrganisation(state, { user, organisationId }) {
    const organisation = state.organisations.find(
      org => parseInt(org.id) === parseInt(organisationId)
    )
    if (organisation) {
      organisation.users.push(user)
    }
  },
  updateUserInOrganisation(state, { data, organisationId, userId }) {
    const organisation = state.organisations.find(
      org => parseInt(org.id) === parseInt(organisationId)
    )
    if (organisation) {
      const user = organisation.users.find(
        user => parseInt(user.id) === parseInt(userId)
      )
      if (user) {
        user.setData(data)
      }
    }
  },
  removeUserFromOrganisation(state, { organisationId, userId }) {
    const organisation = state.organisations.find(
      org => parseInt(org.id) === parseInt(organisationId)
    )
    if (organisation) {
      const userIndex = organisation.users.findIndex(
        user => parseInt(user.id) === parseInt(userId)
      )
      if (userIndex >= 0) {
        organisation.users.splice(userIndex, 1)
      }
    }
  },
  toggleActiveStatus(state, { id, is_active }) {
    const organisation = state.organisations.find(
      org => parseInt(org.id) === parseInt(id)
    )
    if (organisation) {
      organisation.is_active = is_active ? 1 : 0
    }
  }
}

const getters = {}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
