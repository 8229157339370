<template>
  <el-form
    label-position="top"
    :rules="rules"
    ref="form"
    :model="form"
    style="width: 100%;"
  >
    <el-row>
      <el-col :span="11">
        <el-form-item prop="project_number" label="Project nummer">
          <el-input type="text" v-model="form.project_number" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <el-form-item prop="title" label="Titel">
          <el-input type="text" v-model="form.title" />
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="11">
        <el-form-item prop="version" label="Versie nummer">
          <el-input type="text" v-model="form.version" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <el-form-item prop="organisation_id" label="Organisatie">
          <el-select
            style="width: 100%;"
            filterable
            placeholder="Selecteer een organisatie"
            v-model="form.organisation_id"
          >
            <el-option
              v-for="organisation in sortedOrganisations"
              :key="organisation.id"
              :value="organisation.id"
              :label="organisation.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="11" v-if="!existingFormData" v-html="'&nbsp;'"></el-col>
    </el-row>

    <el-row>
      <el-col :span="11">
        <!-- Don't show on update -->
        <el-form-item prop="template_id" label="Gebruik template als basis">
          <el-select
            style="width: 100%;"
            filterable
            placeholder="Selecteer een template"
            v-model="form.template_id"
          >
            <el-option
              v-for="template in templates"
              :key="template.id"
              :value="template.id"
              :label="template.title"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="11" v-if="!existingFormData && form.template_id">
        <!-- Don't show on update -->
        <el-form-item prop="template_id" label="Standaard versiedatum">
          <el-date-picker
            v-model="form.default_version_date"
            type="date"
            placeholder="Selecteer datum"
            :picker-options="pickerOptions"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item>
          <el-button @click.prevent="submitForm" type="primary"
            >Opslaan</el-button
          ><el-button type="link" @click.prevent="cancelForm">Annuleren</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  name: 'ManualForm',
  props: {
    existingFormData: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      form: {
        title: '',
        project_number: '',
        version: '0.1',
        is_template: false,
        organisation_id: null,
        template_id: null,
        default_version_date: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: 'De titel mag niet leeg zijn',
            trigger: ['blur']
          }
        ],
        version: [
          {
            required: true,
            message: 'De versie mag niet leeg zijn',
            trigger: ['blur']
          }
        ],
        organisation_id: [
          {
            required: true,
            message: 'De organisatie mag niet leeg zijn',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('organisation', ['organisations']),
    ...mapState('template', ['templates']),
    sortedOrganisations() {
      return _.orderBy(this.organisations, 'name')
    }
  },
  mounted() {
    if (this.existingFormData) {
      this.initExistingData()
    }

    if (!this.templates.length) {
      this.$store
        .dispatch('template/all')
        .then(
          () => {
            //
          },
          error => {
            this.$notify({
              title:
                'Er is iets misgegaan met het ophalen van de templates. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    initExistingData() {
      this.form.title = this.existingFormData.title
        ? this.existingFormData.title
        : ''

      this.form.version = this.existingFormData.version
        ? this.existingFormData.version
        : ''

      this.form.is_template = this.existingFormData.is_template
        ? this.existingFormData.is_template
        : false

      this.form.project_number = this.existingFormData.project_number
        ? this.existingFormData.project_number
        : ''

      this.form.organisation_id = this.existingFormData.organisation?.id
        ? this.existingFormData.organisation?.id
        : null
    },
    cancelForm() {
      this.$emit('cancel')
    },
    clearForm() {
      this.form.title = ''
      this.form.project_number = ''
      this.form.version = '0.1'
      this.form.is_template = false
      this.form.organisation_id = null
      this.form.template_id = null
      this.form.default_version_date = ''
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submitted', this.form)
        }
      })
    }
  }
}
</script>
