<template>
  <div class="manual-editor" v-if="activeManual">
    <div class="manual-editor__heading">
      <h1 class="manual-editor__title">
        {{ activeManual.title }}<br/>
        <small class="manual-editor__subtitle">{{ activeManual.organisation.name}}</small>
      </h1>
      <router-link
        class="manual-editor__link icon-button icon-button--text"
        target="_blank"
        v-if="this.$route.params.organisationId && user.canEditManual"
        :to="{
          name: 'ManualView',
          params: {
            manualId: activeManual.id,
            organisationId: this.$route.params.organisationId
          }
        }"
      >
        <i class="fal fa-book-open"></i> <span>Bekijk het handboek</span>
      </router-link>
    </div>
    <editor-chapter-add
      :manualId="activeManual.id"
      :chapterIndexReservation="0"
    />
    <draggable
      v-model="draggableChapters"
      @start="dropped = false"
      @end="dropped = true"
      :move="draggableMayMove"
      ghost-class="draggable--ghost"
      handle=".draggable__dragger"
    >
      <div
        v-for="(chapter, index) in draggableChapters"
        :key="`chapter-${index}`"
      >
        <editor-chapter
          :chapter="chapter"
          :dropped="dropped"
          @editting="draggableMayNotMove = true"
          @edittingClosed="draggableMayNotMove = false"
        />
        <editor-chapter-add
          :key="`chapter-add-${index}`"
          :manualId="chapter.manual_id"
          :chapterIndexReservation="index + 1"
        />
      </div>
    </draggable>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EditorChapter from '@/components/Manual/Editor/Chapter/Chapter'
import EditorChapterAdd from '@/components/Manual/Editor/Chapter/Add'
import draggable from 'vuedraggable'
export default {
  name: 'ManualEditor',
  components: {
    EditorChapter,
    EditorChapterAdd,
    draggable
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('manual', ['activeManual']),
    draggableChapters: {
      get() {
        return this.$store.state.manual.activeManual.chapters
      },
      set(chapters) {
        this.orderingLoading = true

        const orderingPayload = {}
        let count = 1
        chapters.forEach(c => {
          orderingPayload[c.id] = count
          count++
        })

        this.$store
          .dispatch('manual/orderChapters', {
            manualId: this.activeManual.id,
            order: orderingPayload
          })
          .then(
            () => {
              this.$store.commit('manual/updateChapterOrder', chapters)
            },
            error => {
              this.$notify({
                title: 'Er is iets misgegaan met de sortering. De melding is:',
                message: error,
                type: 'error'
              })
            }
          )
          .finally(() => {
            this.orderingLoading = true
          })
      }
    }
  },
  data() {
    return {
      loading: false,
      dropped: true,
      draggableMayNotMove: false
    }
  },
  mounted() {
    if (
      !this.user.canEditManual ||
      (!this.$route.params.manualId && this.$route.params.organisationId)
    ) {
      this.$router.push({ name: 'Home' })
    } else {
      this.getManual()
    }
  },
  methods: {
    draggableMayMove() {
      return !this.draggableMayNotMove && this.user.isSuperAdmin
    },
    getManual() {
      this.loading = true
      let action = ''
      let payload = {}

      if (this.$route.params.organisationId) {
        action = 'manual/getSingleByOrganisation'
        payload = {
          manualId: this.$route.params.manualId,
          organisationId: this.$route.params.organisationId
        }
      } else {
        action = 'template/getTemplate'
        payload = {
          id: this.$route.params.manualId
        }
      }

      this.$store
        .dispatch(action, payload)
        .then(
          () => {
            //
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss">
.manual-editor {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    line-height: 27px;
  }

  &__subtitle {
    font-size: 13px;
    color: grey;
  }

  &__link {
    text-decoration: none !important;

    span {
      text-decoration: underline;
    }
  }
}
</style>
