<template>
  <div
    class="block draggable"
    :class="{ 'is--open': chapterIsOpen }"
    v-loading="loading"
  >
    <div class="block__heading" v-if="mutable">
      <chapter-form
        :existing-form-data="chapter"
        @submitted="updateChapter"
        @cancelled="cancelEditting"
        :saving="loading"
      />
    </div>
    <div
      v-else
      class="block__heading draggable__dragger"
      @click.prevent="chapterIsOpen = !chapterIsOpen"
    >
      <div class="block__toggler">
        <button class="icon-button icon-button--toggle">
          <i class="fal fa-chevron-up"></i>
        </button>
        <h1 class="block__title">{{ chapter.title }}</h1>
      </div>
      <div class="block__heading--align-right">
        <div class="block__version">Versie {{ chapter.version }}</div>
        <context-menu v-if="user.isSuperAdmin">
          <li>
            <a
              href="#"
              class="icon-button icon-button--text"
              @click.prevent.stop="editChapter()"
            >
              <i class="fal fa-pen"></i>
              Hoofdstuk wijzigen
            </a>
          </li>
          <li>
            <a
              href="#"
              class="icon-button icon-button--text"
              @click.prevent.stop="deleteChapter()"
            >
              <i class="fal fa-trash-alt"></i>
              Hoofdstuk verwijderen
            </a>
          </li>
        </context-menu>
      </div>
    </div>
    <div class="block__body" v-show="chapterIsOpen">
      <editor-block-add
        :manualId="activeManual.id"
        :chapterId="chapter.id"
        :blockIndexReservation="0"
      />

      <draggable
        v-model="draggableBlocks"
        @start="droppedLocal = false"
        @end="droppedLocal = true"
        :move="draggableMayMove"
        ghost-class="draggable--ghost"
        handle=".draggable__dragger"
      >
        <div v-for="(block, index) in draggableBlocks" :key="index">
          <editor-block
            :block="block"
            :manualId="activeManual.id"
            @editting="draggableMayNotMove = true"
            @edittingClosed="draggableMayNotMove = false"
          />
          <editor-block-add
            :key="`block-add-${index}`"
            :manualId="activeManual.id"
            :chapterId="chapter.id"
            :blockIndexReservation="index + 1"
          />
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import chapterForm from '@/components/Manual/Editor/Chapter/Form'
import editorBlock from '@/components/Manual/Editor/Block/Block'
import editorBlockAdd from '@/components/Manual/Editor/Block/Add'
import draggable from 'vuedraggable'
import ContextMenu from '../../../Helpers/ContextMenu.vue'
export default {
  name: 'EditorChapter',
  components: {
    chapterForm,
    draggable,
    editorBlock,
    editorBlockAdd,
    ContextMenu
  },
  props: {
    chapter: {
      type: Object,
      required: true
    },
    dropped: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('manual', ['activeManual']),
    draggableBlocks: {
      get() {
        return this.chapter.blocks
      },
      set(blocks) {
        this.loading = true

        const orderingPayload = {}
        let count = 1
        blocks.forEach(c => {
          orderingPayload[c.id] = count
          count++
        })

        this.$store
          .dispatch('manual/orderBlocks', {
            manualId: this.activeManual.id,
            chapterId: this.chapter.id,
            order: orderingPayload
          })
          .then(
            () => {
              this.$store.commit('manual/updateBlocksOrderInChapter', {
                chapterId: this.chapter.id,
                blocks
              })
            },
            error => {
              this.$notify({
                title: 'Er is iets misgegaan met de sortering. De melding is:',
                message: error,
                type: 'error'
              })
            }
          )
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  data() {
    return {
      chapterIsOpen: false,
      loading: false,
      mutable: false,
      droppedLocal: true,
      draggableMayNotMove: false
    }
  },
  watch: {
    dropped(val) {
      if (val) {
        // for this chapter to close
        this.chapterIsOpen = false
      }
    }
  },
  methods: {
    draggableMayMove() {
      return !this.draggableMayNotMove && this.user.isSuperAdmin
    },
    updateChapter(data) {
      data.order = this.chapter.order // add this manually because the users cant update it in the form
      this.loading = true
      this.$store
        .dispatch('manual/updateChapter', {
          manualId: this.chapter.manual_id,
          chapterId: this.chapter.id,
          payload: data
        })
        .then(
          () => {
            this.$notify({
              title: 'het hoofdstuk is geupdated',
              type: 'success'
            })

            this.cancelEditting()
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    editChapter() {
      this.mutable = true
      this.$emit('editting')
    },
    cancelEditting() {
      this.mutable = false
      this.$emit('edittingClosed')
    },
    deleteChapter() {
      this.$swal({
        title: 'Weet je het zeker?',
        html:
          '<trong>LET OP:</strong>Met deze actie worden ook alle elementen en documenten verwijderd.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuleren',
        confirmButtonText: 'Ik begrijp het. Verwijder dit hoofdstuk'
      }).then(result => {
        if (result.value) {
          this.loading = true
          this.$store
            .dispatch('manual/deleteChapter', {
              chapterId: this.chapter.id,
              manualId: this.chapter.manual_id
            })
            .then(
              () => {
                this.$notify({
                  title: 'Het hoofdstuk is verwijderd',
                  type: 'success'
                })
              },
              error => {
                this.$notify({
                  title: 'Er is iets misgegaan. De melding is:',
                  message: error,
                  type: 'error'
                })
              }
            )
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
