import Revision from '../models/Revision'
import Document from '../models/Document'
import moment from 'moment'
moment.locale('nl')
export default class Block {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const {
      id,
      chapter_id,
      order,
      title,
      version,
      revisions,
      documents,
      element_type,
      element_code,
      version_date,
      has_page_break
    } = data

    if (id) {
      this.id = id
    }

    if (chapter_id) {
      this.chapter_id = chapter_id
    }

    if (order) {
      this.order = order
    }

    if (title) {
      this.title = title
    }

    if (version) {
      this.version = version
    }

    if (version_date) {
      this.version_date = version_date
    }

    if (element_type) {
      this.element_type = element_type
    }

    if (element_code) {
      this.element_code = element_code
    }

    if (has_page_break) {
      this.has_page_break = has_page_break
    }

    if (revisions) {
      this.revisions = []
      revisions.forEach(rev => {
        this.revisions.push(new Revision(rev))
      })
    }

    if (documents) {
      this.documents = []
      documents.forEach(d => {
        this.documents.push(new Document(d))
      })

      this.activeDocuments = []
      documents.forEach(d => {
        if (d.is_active) {
          this.activeDocuments.push(new Document(d))
        }
      })
    }
  }

  get versionDateFormatted() {
    const dateUtc = moment.utc(this.version_date).toDate()
    return `${moment(dateUtc)
      .local()
      .format('DD MMMM YYYY')}`
  }

  get activeRevision() {
    return this.revisions.find(r => r.is_active === true)
  }

  id = null
  chapter_id = ''
  order = 0
  title = ''
  revisions = []
  documents = []
  activeDocuments = []
  version = null
  element_code = ''
  element_type = ''
  version_date = ''
  has_page_break = false
}
