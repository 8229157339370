<template>
  <div>
    <h1>Organisaties overzicht</h1>
    <el-input
      type="search"
      v-model="searchQuery"
      suffix-icon="el-icon-search"
      class="search-box"
      placeholder="Zoek op organisatienaam"
    />
    <el-table
      stripe
      :data="queriedOrganisations"
      class="has--clickable-row"
      v-loading="loading"
      @row-click="showUsersForOrganisation"
    >
      <el-table-column sortable prop="name" label="Naam"></el-table-column>
      <el-table-column sortable label="Adres" prop="address" width="215"></el-table-column>
      <el-table-column
        width="107"
        sortable
        label="Postcode"
        prop="zipcode"
      ></el-table-column>
      <el-table-column sortable label="Plaats" prop="city" width="181"></el-table-column>
      <el-table-column
        width="90"
        sortable
        prop="memberAmount"
        label="Gebr."
      ></el-table-column>
      <el-table-column width="90" sortable align="center" prop="is_active" label="Status">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.is_active"></i>
          <i class="fa fa-times" v-else></i>
        </template>
      </el-table-column>
      <el-table-column width="50" align="right">
        <template slot-scope="scope">
          <context-menu>
            <li>
              <router-link
                class="icon-button--text"
                :to="{ name: 'OrganisationEdit', params: { id: scope.row.id } }"
              >
                <i class="fal fa-pen"></i>
                Organisatie wijzigen
              </router-link>
            </li>
            <li>
              <a
                href="#"
                class="icon-button--text"
                v-if="scope.row.is_active"
                @click.prevent.stop="toggleActiveStatus(scope.row.id, false)"
              >
                <i class="fal fa-check-circle"></i>
                Organisatie deactiveren
              </a>
              <a
                href="#"
                class="icon-button--text"
                v-else
                @click.prevent.stop="toggleActiveStatus(scope.row.id, true)"
              >
                <i class="fal fa-check-circle"></i>
                Organisatie activeren
              </a>
            </li>
            <li>
              <a
                href="#"
                class="icon-button--text"
                @click.prevent.stop="deleteOrganisation(scope.row.id)"
              >
                <i class="fal fa-trash-alt"></i>
                Organisatie verwijderen
              </a>
            </li>
          </context-menu>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ContextMenu from '@/components/Helpers/ContextMenu'
export default {
  name: 'OrganisationOverview',
  components: {
    ContextMenu
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('organisation', ['organisations']),
    queriedOrganisations() {
      if (this.searchQuery !== '') {
        return this.organisations.filter(item => {
          if (
            item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0
          ) {
            return item
          }
        })
      }

      return this.organisations
    }
  },
  data() {
    return {
      loading: false,
      userManagementModalOpen: false,
      activeOrganisation: null,
      searchQuery: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.user.isSuperAdmin) {
        this.loading = true
        this.$store
          .dispatch('organisation/getAll')
          .then(
            () => {
              //
            },
            error => {
              this.$notify({
                title: 'Er is iets misgegaan. De melding is:',
                message: error,
                type: 'error'
              })
            }
          )
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$router.push({ name: 'Home' })
      }
    },
    toggleActiveStatus(id, is_active) {
      // the is_active is the new value, so to show the correct message we have to invert the value.
      const showCorrectMessage = !is_active
      const htmlText = showCorrectMessage
        ? '<strong>LET OP:</strong>Met deze actie wordt deze organisatie op inactief gezet.'
        : '<strong>LET OP:</strong>Met deze actie wordt deze organisatie op actief gezet.'
      const buttonText = showCorrectMessage
        ? 'Deactiveer deze organisatie'
        : 'Activeer deze organisatie'
      const successText = showCorrectMessage
        ? 'De organisatie is gedeactiveerd'
        : 'De organisatie is geactiveerd'
      if (id) {
        this.$swal({
          title: 'Weet je het zeker?',
          html: htmlText,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Annuleren',
          confirmButtonText: buttonText
        }).then(result => {
          if (result.value) {
            this.loading = true
            this.$store
              .dispatch('organisation/toggleActiveStatus', {
                id,
                is_active
              })
              .then(
                () => {
                  this.$notify({
                    title: successText,
                    type: 'success'
                  })
                },
                error => {
                  this.$notify({
                    title: 'Er is iets misgegaan. De melding is:',
                    message: error,
                    type: 'error'
                  })
                }
              )
              .finally(() => {
                this.loading = false
              })
          }
        })
      }
    },
    deleteOrganisation(id) {
      if (id) {
        this.$swal({
          title: 'Weet je het zeker?',
          html:
            '<trong>LET OP:</strong>Met deze actie worden ook alle gebruikers en handboeken van deze organisatie verwijderd.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Annuleren',
          confirmButtonText: 'Ik begrijp het. Verwijder deze organisatie'
        }).then(result => {
          if (result.value) {
            this.loading = true
            this.$store
              .dispatch('organisation/delete', id)
              .then(
                () => {
                  this.$notify({
                    title: 'De organisatie verwijderd',
                    type: 'success'
                  })
                },
                error => {
                  this.$notify({
                    title: 'Er is iets misgegaan. De melding is:',
                    message: error,
                    type: 'error'
                  })
                }
              )
              .finally(() => {
                this.loading = false
              })
          }
        })
      }
    },
    showUsersForOrganisation(organisation) {
      this.$router.push({
        name: 'UserOverview',
        params: { id: organisation.id }
      })
    }
  }
}
</script>
<style lang="scss">
.logo {
  width: 175px;
}
</style>
