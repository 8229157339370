<template>
  <div>
    <el-row>
      <el-col>
        <h1>Toevoegen van een nieuwe organisatie</h1>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <organisation-form @submitted="addOrganisation" />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import OrganisationForm from '@/components/Organisation/Form'
export default {
  name: 'OrganisationAdd',
  components: {
    OrganisationForm
  },
  mounted() {},
  methods: {
    addOrganisation(data) {
      const formData = new FormData()

      formData.append('address', data.address)
      formData.append('city', data.city)
      formData.append('name', data.name)
      formData.append('zipcode', data.zipcode)

      if (data.logo) {
        formData.append('file', data.logo)
      }

      this.saving = true
      this.$store
        .dispatch('organisation/add', formData)
        .then(
          organisationId => {
            this.$notify({
              title: 'De organisatie is opgeslagen',
              type: 'success'
            })
            this.$router.push({
              name: 'UserOverview',
              params: { id: organisationId }
            })
          },
          error => {
            let errorMessage = ''
            for (const e in error) {
              errorMessage += `- ${error[e]}<br>`
            }
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              dangerouslyUseHTMLString: true,
              message: errorMessage,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>
