<template>
  <el-form
    label-position="top"
    :rules="rules"
    ref="form"
    :model="form"
    style="width: 100%;"
  >
    <el-row>
      <el-col :span="11">
        <el-form-item prop="title" label="Titel">
          <el-input type="text" v-model="form.title" />
        </el-form-item>
      </el-col>
      <el-col :span="1" v-html="'&nbsp;'"></el-col>
      <el-col :span="6">
        <el-form-item prop="version" label="Versie nummer">
          <el-input type="text" v-model="form.version" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item>
          <el-button @click.prevent="submitForm" type="primary"
            >Opslaan</el-button
          >
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
export default {
  name: 'TemplateForm',
  props: {
    existingFormData: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      form: {
        title: '',
        version: '0.1',
        is_template: true
      },
      rules: {
        title: [
          {
            required: true,
            message: 'De titel mag niet leeg zijn',
            trigger: ['blur']
          }
        ],
        version: [
          {
            required: true,
            message: 'De versie mag niet leeg zijn',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  mounted() {
    if (this.existingFormData) {
      this.initExistingData()
    }
  },
  watch: {
    existingFormData: function (newVal, oldVal) { // watch it
      this.initExistingData()
    }
  },
  methods: {
    initExistingData() {
      this.form.title = this.existingFormData.title
        ? this.existingFormData.title
        : ''

      this.form.version = this.existingFormData.version
        ? this.existingFormData.version
        : ''
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submitted', this.form)
        }
      })
    }
  }
}
</script>
