<template>
  <el-menu mode="horizontal" class="main-menu">
    <el-menu-item>
      <router-link :to="{ name: 'Home' }">Handboeken</router-link>
    </el-menu-item>
    <el-submenu :index="'1'" v-if="user.canViewOrganisation">
      <template slot="title">Organisatie</template>
      <el-menu-item v-if="user.isSuperAdmin">
        <router-link :to="{ name: 'OrganisationOverview' }"
          >Bekijk alle organisaties</router-link
        >
      </el-menu-item>
      <el-menu-item v-if="user.isSuperAdmin">
        <router-link :to="{ name: 'OrganisationAdd' }"
          >Nieuwe organisatie toevoegen</router-link
        >
      </el-menu-item>
      <!--
      <el-menu-item v-if="user.canEditOrganisation && user.organisation_id">
        <router-link
          :to="{
            name: 'OrganisationEdit',
            params: { id: user.organisation_id }
          }"
          >Wijzig organisatie</router-link
        >
      </el-menu-item>
      -->
      <el-menu-item v-if="user.canViewOrganisation && user.organisation_id">
        <router-link
          :to="{
            name: 'UserOverview',
            params: { id: user.organisation_id }
          }"
          >Bekijk gebruikers</router-link
        >
      </el-menu-item>
    </el-submenu>
    <el-menu-item>
      <router-link
        :to="{ name: 'ManualView', params: { manualId: 1, organisationId: 2 } }"
        >KYS Handleiding</router-link
      >
    </el-menu-item>
    <el-menu-item v-if="user.isSuperAdmin">
      <router-link :to="{ name: 'TemplateOverview' }">Templates</router-link>
    </el-menu-item>
    <el-submenu :index="'2'">
      <template slot="title">{{ user.name }}</template>
      <el-menu-item>
        <router-link :to="{ name: 'UserUpdatePassword' }"
          >Wachtwoord wijzigen</router-link
        >
      </el-menu-item>
      <el-menu-item>
        <a href="#" @click.prevent="logoutUser">Uitloggen</a>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'NavbarMainMenu',
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    logoutUser() {
      this.$store.commit('user/logOut')
    }
  }
}
</script>
<style lang="scss">
.main-menu.el-menu.el-menu--horizontal {
  border-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > .el-submenu .el-submenu__title,
  & > .el-submenu .el-submenu__title i,
  & > .el-menu-item a,
  & > .el-menu-item a:hover {
    color: $--color-text-regular;
    text-decoration: none;
  }

  > .el-menu-item {
    height: auto;
    margin: 0 0.5rem;
  }

  & > .el-submenu {
    border-bottom: 2px solid transparent;
  }

  & > .el-submenu:hover,
  & > .el-menu-item:hover {
    border-bottom-color: #00a1b8 !important;
  }

  .el-menu-item.is-active {
    border: 0;
  }
}

body .el-menu {
  &--popup {
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09),
      0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    list-style: none;
    padding: 0.5rem 0 1rem;
    margin: 0;
  }

  &--popup li {
    text-align: left;
    overflow: initial;
    margin: 0;
    height: auto;
    background-color: none;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    a {
      display: block;
      padding: 0.5rem 0.75rem;
      margin: 0.3rem;
      border-radius: 8px;
      color: $--color-text-regular;
      line-height: 1.6;

      &:hover {
        background-color: $--color-primary-light-2;
        text-decoration: none;
      }
    }
  }
}
</style>
