<template>
  <div>
    <h1>Templates overzicht</h1>
    <el-table
      stripe
      :data="templates"
      class="has--clickable-row"
      v-loading="loading"
    >
      <el-table-column sortable prop="title" label="Titel"></el-table-column>
      <el-table-column
        sortable
        prop="version"
        label="versie"
        width="100"
        align="right"
      ></el-table-column>
      <el-table-column
        sortable
        prop="versionDateFormatted"
        label="Laatst gewijzigd"
        width="170"
      ></el-table-column>

      <el-table-column width="175" align="right">
        <template slot-scope="scope">
          <context-menu>
            <li style="width: 300px;">
              <router-link
                class="icon-button icon-button--text"
                :to="{
                  name: 'TemplateEdit',
                  params: {
                    manualId: scope.row.id
                  }
                }"
              >
                <i class="fal fa-pen"></i>
                Gegevens template wijzigen
              </router-link>
            </li>
            <li style="width: 300px;">
              <router-link
                class="icon-button icon-button--text"
                :to="{
                  name: 'TemplateEditor',
                  params: {
                    manualId: scope.row.id
                  }
                }"
              >
                <i class="fal fa-book"></i>
                Inhoud template bewerken
              </router-link>
            </li>
            <li style="width: 300px;">
              <a
                href="#"
                class="icon-button icon-button--text"
                @click.prevent.stop="copyTemplate(scope.row.id)"
              >
                <i class="fal fa-copy"></i>
                Template kopiëren
              </a>
            </li>
            <li style="width: 300px;">
              <a
                href="#"
                class="icon-button icon-button--text"
                @click.prevent.stop="deleteTemplate(scope.row.id)"
              >
                <i class="fal fa-trash-alt"></i>
                Template verwijderen
              </a>
            </li>
          </context-menu>
        </template>
      </el-table-column>
    </el-table>

    <el-button
      type="primary"
      @click.prevent="addDialogVisible = true"
      v-if="user.isSuperAdmin"
    >
      Voeg een template toe
    </el-button>
    <el-dialog
      title="Template toevoegen"
      :visible.sync="addDialogVisible"
      v-if="user.isSuperAdmin"
    >
      <template-form @submitted="addTemplate" :saving="loading" />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TemplateForm from '@/components/Template/Form'
import ContextMenu from '@/components/Helpers/ContextMenu'
export default {
  name: 'TemplateOverview',
  components: {
    TemplateForm,
    ContextMenu
  },
  data() {
    return {
      loading: false,
      addDialogVisible: false
    }
  },
  computed: {
    ...mapState('template', ['templates']),
    ...mapState('user', ['user'])
  },
  mounted() {
    if (!this.templates.length) {
      this.$store
        .dispatch('template/all')
        .then(
          () => {
            //
          },
          error => {
            this.$notify({
              title:
                'Er is iets misgegaan met het ophalen van de templates. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    addTemplate(data) {
      this.loading = true
      this.$store
        .dispatch('manual/addManual', data)
        .then(
          () => {
            this.$notify({
              title: 'De template is toegevoegd',
              type: 'success'
            })
            this.addDialogVisible = false
          },
          error => {
            this.$notify({
              title:
                'Er is iets misgegaan met het opslaan van de template. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    copyTemplate(id) {
      this.loading = true
      this.$store
        .dispatch('template/copyTemplateToTemplate', { id })
        .then(
          () => {
            this.$notify({
              title: 'De template is gekopieerd',
              type: 'success'
            })
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    deleteTemplate(id) {
      if (id) {
        this.$swal({
          title: 'Weet je het zeker?',
          html:
            '<trong>LET OP:</strong>Met deze actie worden ook alle hoofdstukken, elementen en documenten verwijderd.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Annuleren',
          confirmButtonText: 'Ik begrijp het. Verwijder dit template'
        }).then(result => {
          if (result.value) {
            this.loading = true
            this.$store
              .dispatch('manual/delete', { id, is_template: true })
              .then(
                () => {
                  this.$notify({
                    title: 'De template is verwijderd',
                    type: 'success'
                  })
                },
                error => {
                  this.$notify({
                    title: 'Er is iets misgegaan. De melding is:',
                    message: error,
                    type: 'error'
                  })
                }
              )
              .finally(() => {
                this.loading = false
              })
          }
        })
      }
    }
  }
}
</script>
