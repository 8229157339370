<template>
  <div class="revision">
    <revision-form
      class="revision__body"
      @submitted="updateActiveRevision"
      @cancelled="dialogVisible = false"
      :revision="activeRevision"
      :saving="loading"
      v-if="dialogVisible"
    />
    <div class="revision__body" v-if="revisionToShow && !dialogVisible">
      <div
        class="revision__reader html-content"
        v-html="revisionToShow.description"
      ></div>
    </div>
    <div class="revision__sidebar" v-if="!dialogVisible">
      <a
        href="#"
        @click.prevent.stop="currentRevision = null"
        v-if="currentRevision"
        style="margin-bottom: 1rem;"
      >
        <i class="fa fa-arrow-left"></i> Terug naar actieve revisie
      </a>

      <div class="revision__metadata">
        <div class="revision__options">
          <h4 class="revision__subtitle">Revisie omschrijving</h4>
          <!-- You can only edit an active revision -->
          <button
            v-if="
              revisionToShow.is_active &&
                (user.isSuperAdmin ||
                  (user.canEditRevision && isValidElementType))
            "
            class="icon-button"
            @click.prevent.stop="editActiveRevision"
          >
            <i class="fal fa-pen"></i>
          </button>
        </div>
        <h3 class="revision__title">{{ revisionToShow.title }}</h3>
      </div>

      <span class="revision__subtitle"> Laatst gewijzigd: </span><br />
      {{ revisionToShow.updatedDateFormatted }}

      <div v-if="inactiveRevisions.length">
        <h4 class="revision__subtitle">Oudere revisies:</h4>
        <ul class="list">
          <li
            class="list__item"
            v-for="(revision, index) in inactiveRevisions"
            :key="index"
          >
            <a href="#" @click.prevent.stop="currentRevision = revision.id">
              {{ revision.createdDateFormatted }}
            </a>
            <small class="list__subtitle">
              <hr></small
            >
          </li>
        </ul>
      </div>
      <el-button
        v-if="user.isSuperAdmin || (user.canAddRevision && isValidElementType)"
        style="margin-top: 1rem"
        type="primary"
        @click.prevent.stop="copyActiveRevision"
        >Maak een nieuwe revisie</el-button
      >
    </div>
  </div>
</template>
<script>
import RevisionForm from '@/components/Manual/Editor/Revision/Form'
import { mapState } from 'vuex'
export default {
  name: 'RevisionOverview',
  components: {
    RevisionForm
  },
  props: {
    revisions: {
      type: Array,
      required: true
    },
    chapterId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      currentRevision: null // if the current Revision is set; show this revision to the user instead of the active one
    }
  },
  computed: {
    ...mapState('manual', ['activeManual']),
    ...mapState('user', ['user']),
    activeRevision() {
      return this.revisions.find(r => r.is_active === true)
    },
    revisionToShow() {
      if (this.currentRevision) {
        return this.revisions.find(r => r.id === this.currentRevision)
      }
      return this.activeRevision
    },
    inactiveRevisions() {
      return this.revisions.filter(r => r.is_active === false)
    },
    isValidElementType() {
      const chapter = this.activeManual.chapters.find(
        c => c.id === this.chapterId
      )
      const block = chapter.blocks.find(
        b => b.id === this.activeRevision.block_id
      )
      return (
        block.element_type === 'Formulier' ||
        block.element_type === 'Plan van Aanpak'
      )
    }
  },
  methods: {
    copyActiveRevision() {
      this.loading = true
      this.$store
        .dispatch('manual/copyRevision', {
          manualId: this.activeManual.id,
          chapterId: this.chapterId,
          blockId: this.activeRevision.block_id,
          revisionId: this.activeRevision.id
        })
        .then(
          () => {
            this.$notify({
              title: 'Nieuwe revisie is aangemaakt',
              type: 'success'
            })
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    editActiveRevision() {
      this.dialogVisible = true
    },
    updateActiveRevision(data) {
      this.loading = true

      // Sanitize the string. Somehow, tables in tinymce can contain width attributes. there is no easy way to skip these.
      // therefor, filter them here. The only to do this is apparently by dom parser
      const parser = new DOMParser()
      const parsedResult = parser.parseFromString(data.description, 'text/html')

      const tags = parsedResult.getElementsByTagName('td')
      for (const tag of tags) {
        if (tag.hasAttribute('width')) {
          tag.removeAttribute('width')
        }
      }

      data.description = parsedResult.getElementsByTagName('body')[0].innerHTML

      this.$store
        .dispatch('manual/updateRevision', {
          payload: data,
          manualId: this.activeManual.id,
          chapterId: this.chapterId,
          blockId: this.activeRevision.block_id,
          revisionId: this.activeRevision.id
        })
        .then(
          () => {
            this.$notify({
              title: 'Revisie geupdated',
              type: 'success'
            })
            this.dialogVisible = false
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
