import Chapter from '../models/Chapter'
import User from '../models/User'
import Organisation from '../models/Organisation'
import moment from 'moment'
moment.locale('nl')

export default class Manual {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const {
      id,
      title,
      is_template,
      user,
      organisation,
      version,
      chapters,
      version_date,
      project_number
    } = data

    if (id) {
      this.id = id
    }

    if (title) {
      this.title = title
    }

    if (version) {
      this.version = version
    }

    if (is_template) {
      this.is_template = is_template
    }

    if (project_number) {
      this.project_number = project_number
    }

    if (version_date) {
      this.version_date = version_date
    }

    if (user) {
      this.user = new User(user)
    }

    if (organisation) {
      this.organisation = new Organisation(organisation)
    }

    if (chapters) {
      this.chapters = []
      chapters.forEach(c => {
        this.chapters.push(new Chapter(c))
      })
    }
  }

  get versionDateFormatted() {
    const dateUtc = moment.utc(this.version_date).toDate()
    return `${moment(dateUtc)
      .local()
      .format('DD MMMM YYYY')}`
  }

  id = null
  version = null
  title = ''
  version_date = ''
  project_number = ''
  is_template = false
  user = {}
  organisation = {}
  chapters = []
}
