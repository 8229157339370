<template>
  <div
    v-loading="loading"
    v-if="user.isSuperAdmin"
    class="line-add"
    :class="{ 'is--visible': addChapterDialog }"
  >
    <div class="line-add__inner" v-if="addChapterDialog == true">
      <chapter-form
        @submitted="addChapter"
        @cancelled="cancelChapterAdd"
        :saving="loading"
      />
    </div>
    <div
      class="line-add__inner line-add__inner--buttons"
      @click.prevent="addChapterDialog = true"
      v-else
    >
      <button class="line-add__button">
        <i class="fal fa-plus-circle line-add__icon"></i></button
      ><br />
      <span class="line-add__text">Voeg hier een hoofdstuk toe</span>
    </div>
  </div>
  <div v-else class="is--empty" />
</template>
<script>
import { mapState } from 'vuex'
import chapterForm from '@/components/Manual/Editor/Chapter/Form'
export default {
  name: 'EditorChapterAdd',
  props: {
    chapterIndexReservation: {
      // the index in which the chapter should be pushed in the chapters array to define the new ordering of all chapters
      type: Number,
      required: true
    },
    manualId: {
      type: Number,
      required: true
    }
  },
  components: {
    chapterForm
  },
  data() {
    return {
      addChapterDialog: false,
      loading: false
    }
  },
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    cancelChapterAdd() {
      this.addChapterDialog = false
    },
    addChapter(data) {
      this.loading = true
      data.order = this.chapterIndexReservation // add order based on the prop
      this.$store
        .dispatch('manual/addChapter', {
          payload: data,
          manualId: this.manualId
        })
        .then(
          () => {
            this.addChapterDialog = false
            this.$notify({
              title: 'Het hoofstuk is toegevoegd',
              type: 'success'
            })
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
