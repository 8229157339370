<template>
  <div>
    <h1>Geen toegang</h1>
    <p>
      Deze organisatie bestaat niet, of is gedeactiveerd.
    </p>
  </div>
</template>
<script>
export default {
  name: 'Unavailable'
}
</script>
