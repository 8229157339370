import User from '../models/User'
import Image from '../models/Image'
export default class Organisation {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const { id, name, address, zipcode, city, users, image, is_active } = data

    if (id) {
      this.id = id
    }

    if (name && name !== '') {
      this.name = name
    }

    if (address && address !== '') {
      this.address = address
    }

    if (zipcode && zipcode !== '') {
      this.zipcode = zipcode
    }

    if (city && city !== '') {
      this.city = city
    }

    if (is_active !== '') {
      this.is_active = is_active
    }

    if (image) {
      this.logo = new Image(image)
    }

    if (users && users.length) {
      users.forEach(u => {
        this.users.push(new User(u))
      })
    }
  }

  get addressHorizontal() {
    return `${this.address} ${this.zipcode} ${this.city}`
  }

  get memberAmount() {
    return this.users.length
  }

  id = null
  name = ''
  address = ''
  zipcode = ''
  city = ''
  is_active = ''
  logo = {}
  users = []
}
