<template>
  <el-form :rules="rules" ref="form" :model="form" v-loading="saving">
    <el-row>
      <el-col :span="18">
        <el-form-item prop="title" label="Titel">
          <el-input type="text" v-model="form.title" />
        </el-form-item>
      </el-col>
      <el-col :span="2" v-html="'&nbsp;'"></el-col>
      <el-col :span="4">
        <el-form-item prop="version" label="Versie">
          <el-input type="text" v-model="form.version" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button type="primary" @click.prevent="submitForm"
          >Opslaan</el-button
        >
        <el-button type="link" @click.prevent="cancelForm">Annuleren</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
// import { mapState } from 'vuex'
export default {
  name: 'BlockForm',
  props: {
    saving: {
      type: Boolean,
      required: true
    },
    existingFormData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      form: {
        title: '',
        version: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: 'De titel mag niet leeg zijn',
            trigger: ['blur']
          }
        ],
        version: [
          {
            required: true,
            message: 'De versie mag niet leeg zijn',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  mounted() {
    if (this.existingFormData) {
      this.form.title = this.existingFormData.title
        ? this.existingFormData.title
        : ''
      this.form.version = this.existingFormData.version
        ? this.existingFormData.version
        : ''
    }
  },
  methods: {
    cancelForm() {
      this.$emit('cancelled')
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submitted', this.form)
        }
      })
    }
  }
}
</script>
