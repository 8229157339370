<template>
  <div v-loading="loading">
    <template v-if="organisationToEdit">
      <h2>{{ organisationToEdit.name }} wijzigen</h2>
      <organisation-form
        @submitted="saveOrganisation"
        :existing-form-data="organisationToEdit"
      />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import OrganisationForm from '@/components/Organisation/Form'
export default {
  name: 'name',
  data() {
    return {
      loading: false
    }
  },
  components: {
    OrganisationForm
  },
  computed: {
    ...mapState('organisation', ['organisations']),
    organisationToEdit() {
      return this.organisations.find(
        o => parseInt(o.id) === parseInt(this.$route.params.id)
      )
    }
  },
  mounted() {
    if (!this.organisations.length) {
      this.loading = true
      this.$store
        .dispatch('organisation/getSingle', this.$route.params.id)
        .then(
          () => {
            //
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    saveOrganisation(data) {
      const formData = new FormData()

      formData.append('address', data.address)
      formData.append('city', data.city)
      formData.append('name', data.name)
      formData.append('zipcode', data.zipcode)
      if (data.logo) {
        formData.append('file', data.logo)
      }

      this.saving = true
      this.$store
        .dispatch('organisation/update', {
          payload: formData,
          organisationId: this.organisationToEdit.id
        })
        .then(
          () => {
            this.$notify({
              title: 'De organisatie is opgeslagen',
              type: 'success'
            })
            this.$router.push({
              name: 'UserOverview',
              params: { id: this.$route.params.id }
            })
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>
