import moment from 'moment'
export default class Document {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const {
      id,
      filename,
      url,
      is_active,
      revision_id,
      original_filename,
      created_at
    } = data
    if (id) {
      this.id = id
    }

    if (created_at) {
      this.created_at = created_at
    }

    if (filename) {
      this.filename = filename
    }

    // if the original_filename is present; use that as a filename instead
    if (original_filename) {
      this.filename = original_filename
    }

    if (url) {
      this.url = url
    }

    if (is_active) {
      this.is_active = true
    }

    if (revision_id) {
      this.revision_id = revision_id
    }
  }

  get createdDateFormatted() {
    const dateUtc = moment.utc(this.created_at).toDate()
    return `${moment(dateUtc)
      .local()
      .format('DD MMMM YYYY H:mm')}`
  }

  id = null
  filename = ''
  url = ''
  is_active = false
  revision_id = null
}
