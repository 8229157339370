<template>
  <el-container class="container" v-loading="loading">
    <h2>Reset je wachtwoord</h2>
    <el-form :rules="rules" ref="passwordForm" :model="passwordForm">
      <el-row>
        <el-col :xs="24" :md="11">
          <el-form-item label="Nieuw wachtwoord" prop="password">
            <el-input
              name="password"
              type="password"
              v-model="passwordForm.password"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="2" v-html="'&nbsp;'"></el-col>
        <el-col :xs="24" :md="11">
          <el-form-item label="Nieuw wachtwoord check" prop="passwordRepeat">
            <el-input
              name="passwordRepeat"
              type="password"
              v-model="passwordForm.passwordRepeat"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>&nbsp;</el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-button type="primary" @click.prevent="submitPassword"
            >Verander wachtwoord</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </el-container>
</template>
<script>
import { passwordStrings } from '@/config'
export default {
  name: 'ResetPassword',
  data() {
    const checkPasswordMatch = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(passwordStrings.empty))
      } else {
        if (this.passwordForm.passwordRepeat !== '') {
          this.$refs.passwordForm.validateField('passwordRepeat')
        }
        callback()
      }
    }
    const checkPasswordMatch1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(passwordStrings.emptyConfirm))
      } else if (value !== this.passwordForm.password) {
        callback(new Error(passwordStrings.passwordsDontMatch))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      passwordForm: {
        password: '',
        passwordRepeat: ''
      },
      rules: {
        password: [
          {
            validator: checkPasswordMatch,
            trigger: 'change'
          },
          {
            required: true,
            message: 'Het wachtwoord mag niet leeg zijn',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!$#%]).*$/,
            message:
              'Het wachtwoord moet minimaal bestaan uit 1 hoofdletter, 1 kleine letter, 1 nummer en 1 speciaal karakter.',
            trigger: ['blur', 'change']
          },
          {
            min: 8,
            max: 50,
            message:
              'Het wachtwoord moet tussen de 8 en 50 karakters lang zijn',
            trigger: ['blur', 'change']
          }
        ],
        passwordRepeat: [
          {
            validator: checkPasswordMatch1,
            trigger: 'change'
          },
          {
            required: true,
            message: 'De wachtwoord bevestiging mag niet leeg zijn',
            trigger: ['blur', 'change']
          },
          {
            min: 8,
            max: 50,
            message:
              'De wachtwoord bevestiging moet tussen de 8 en 50 karakters lang zijn',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  methods: {
    submitPassword() {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('user/updatePassword', {
              password: this.passwordForm.password,
              passwordRepeat: this.passwordForm.passwordRepeat
            })
            .then(
              () => {
                this.$notify({
                  title: 'Je wachtwoord is geupdated',
                  type: 'success'
                })
              },
              error => {
                this.$notify({
                  title: 'Er is iets misgegaan. De melding is:',
                  message: error,
                  type: 'error'
                })
              }
            )
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
