<template>
  <div v-loading="loading">
    <template v-if="activeManual">
      <h1>{{ activeManual.title }} wijzigen</h1>
      <template-form
        :existing-form-data="activeManual"
        @submitted="updateTemplate"
        :saving="loading"
      />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TemplateForm from '@/components/Template/Form'
export default {
  name: 'TemplateEdit',
  components: {
    TemplateForm
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('manual', ['activeManual'])
  },
  mounted() {
    if (this.$route.params.manualId) {
      this.loading = true
      this.$store
        .dispatch('template/getTemplate', {
          id: this.$route.params.manualId
        })
        .then(
          () => {
            //
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    updateTemplate(data) {
      this.loading = true
      this.$store
        .dispatch('manual/updateManual', {
          dto: data,
          manualId: this.activeManual.id
        })
        .then(
          () => {
            this.$notify({
              title: 'Het handboek is geupdated',
              type: 'success'
            })
            // reset activeManual and go back to template overview
            this.$store.commit('manual/setActiveManual', null)
            this.$router.push({ name: 'TemplateOverview' })
          },
          error => {
            this.$notify({
              title: 'Er is iets misgegaan. De melding is:',
              message: error,
              type: 'error'
            })
          }
        )
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
