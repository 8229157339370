import Block from '../models/Block'
export default class Chapter {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const { id, manual_id, order, title, version, blocks } = data

    if (id) {
      this.id = id
    }

    if (manual_id) {
      this.manual_id = manual_id
    }

    if (order) {
      this.order = order
    }

    if (title) {
      this.title = title
    }

    if (version) {
      this.version = version
    }

    if (blocks) {
      this.blocks = []
      blocks.forEach(b => {
        this.blocks.push(new Block(b))
      })
    }
  }

  id = null
  manual_id = ''
  order = 0
  title = ''
  version = null
  blocks = []
}
