<template>
  <div>
    <h1>Niks gevonden. 404.</h1>
  </div>
</template>
<script>
export default {
  name: 'FourOFour'
}
</script>
