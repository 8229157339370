export default class Role {
  constructor(data) {
    this.setData(data)
  }

  setData(data) {
    const { name, id } = data

    if (id) {
      this.id = id
    }

    if (name) {
      this.name = name
    }
  }

  get readableName() {
    switch (this.name) {
      case 'superAdministrator':
        return 'Super admin'
      case 'administrator':
        return 'Organisatie beheerder'
      case 'manualManager':
        return 'Handboek beheerder'
      case 'manualViewer':
        return 'Medewerker (met download)'
      case 'manualViewerBasic':
        return 'Medewerker (zonder download)'
      case 'auditor':
        return 'Auditor (zonder download)'
    }
  }

  id = null
  name = ''
}
