<template>
  <div ref="content" class="html-content" v-html="clonedContent"></div>
</template>
<script>
/*
This is a helper component that listens to all anchors inside a content and determine if it should navigate internally or externally(target blank)
*/
import { mapState } from 'vuex'
export default {
  name: 'HtmlContent',
  props: {
    content: {
      required: true,
      type: String
    },
    manualId: {
      required: true,
      type: Number
    },
    chapterId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      clonedContent: ''
    }
  },
  computed: {
    ...mapState('manual', ['activeManual']),
    manual() {
      return this.activeManual.id === this.manualId ? this.activeManual : null
    },
    blocksInManual() {
      const blocks = []
      if (this.manual) {
        this.manual.chapters.forEach(chapter => {
          blocks.push(...chapter.blocks)
        })
      }

      return blocks
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    content() {
      this.init()
    }
  },
  methods: {
    init() {
      this.clonedContent = (' ' + this.content).slice(1)
      // replace all {element-code} with actual links to the element codes
      // eslint-disable-next-line
      const regexTest = /{(.*?)}/g
      const words = this.content.match(regexTest)
      if (words) {
        for (let i = 0; i < words.length; i++) {
          const str = words[i]
          const elementCode = str.substring(1, str.length - 1)
          const element = this.findBlockByElementCode(elementCode)
          if (element) {
            this.clonedContent = this.clonedContent.replaceAll(
              str,
              `<a href="#" data-el="${element.id}" class="el-click">${elementCode}</a>`
            )
          }
        }
      }

      this.$nextTick(() => {
        if (this.$refs.content) {
          const internalLinks = this.$refs.content.querySelectorAll(
            'a.el-click'
          )

          if (internalLinks) {
            internalLinks.forEach(anchor => {
              if (anchor && anchor.getAttribute('data-el')) {
                anchor.addEventListener('click', e => {
                  e.preventDefault()
                  this.$emit(
                    'internalLinkClicked',
                    anchor.getAttribute('data-el')
                  )
                })
              }
            })
          }
        }
      })
    },
    findBlockByElementCode(elementCode) {
      return this.blocksInManual.find(el => {
        if (el.element_code && elementCode) {
          return el.element_code.toLowerCase() === elementCode.toLowerCase()
        }
        return false
      })
    }
  }
}
</script>
