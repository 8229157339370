import Role from '../models/Role'
export default class User {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const {
      first_name,
      last_name,
      email,
      id,
      user_roles,
      organisation_id,
      accepted_privacy_statement
    } = data
    this.id = id
    this.first_name = first_name
    this.last_name = last_name
    this.email = email

    if (id) {
      this.id = id
    }

    if (first_name) {
      this.first_name = first_name
    }

    if (last_name) {
      this.last_name = last_name
    }

    if (email) {
      this.email = email
    }

    if (organisation_id) {
      this.organisation_id = organisation_id
    }

    if (accepted_privacy_statement) {
      this.accepted_privacy_statement = accepted_privacy_statement
    }

    if (user_roles && user_roles.length) {
      this.roles = []
      user_roles.forEach(r => {
        this.roles.push(new Role(r))
      })
    }
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  get isSuperAdmin() {
    return this.roles.find(r => r.name === 'superAdministrator') !== undefined
  }

  get isOrganisationAdmin() {
    return this.roles.find(r => r.name === 'administrator') !== undefined
  }

  get isManualManager() {
    return this.roles.find(r => r.name === 'manualManager') !== undefined
  }

  get isManualViewer() {
    return this.roles.find(r => r.name === 'manualViewer') !== undefined
  }

  get roleName() {
    const names = this.roles.map(r => r.readableName)
    return names.join(', ')
  }

  get hasAcceptedPrivacyStatement() {
    return this.accepted_privacy_statement === 1
  }

  get canEditManual() {
    return this.isSuperAdmin || this.isManualManager
  }

  get canDownloadManual() {
    return this.isSuperAdmin || this.isOrganisationAdmin || this.isManualManager || this.isManualViewer
  }

  get canEditOrganisation() {
    return this.isSuperAdmin
  }

  get canViewOrganisation() {
    return this.isSuperAdmin || this.isOrganisationAdmin || this.isManualManager
  }

  get canAddRevision() {
    return this.isSuperAdmin || this.isOrganisationAdmin || this.isManualManager
  }

  get canEditRevision() {
    return this.isSuperAdmin || this.isOrganisationAdmin || this.isManualManager
  }

  id = null
  first_name = ''
  last_name = ''
  email = ''
  roles = []
  organisation_id = null
  accepted_privacy_statement = 0
}
