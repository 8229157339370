export default class Image {
  constructor(data = false) {
    if (data) {
      this.setData(data)
    }
  }

  setData(data) {
    const { id, filename, url, organisation_id, original_filename } = data
    if (id) {
      this.id = id
    }

    if (filename) {
      this.filename = filename
    }

    // if the original_filename is present; use that as a filename instead
    if (original_filename) {
      this.filename = original_filename
    }

    if (url) {
      this.url = url
    }

    if (organisation_id) {
      this.organisation_id = organisation_id
    }
  }

  id = null
  filename = ''
  url = ''
  organisation_id = null
}
